<template>
	<div class="TypemsgListView">
		<div v-if="type == 2" class="list-top">
			<span>
				<input v-model="jzrObj.name"  @click="showJzrList" readonly class="ipt ipt4" placeholder="就诊人" />
				<van-icon class="inputIcon" v-show="!jzrObj.name" name="arrow-down" color="#999999" />
				<van-icon @click.stop="deleteJzrObj" class="inputIcon" v-show="jzrObj.name" name="cross" color="#999999" />
			</span>
			<span>
				<input v-model="hzTime" @click="isShow.hzTime=true" readonly class="ipt ipt6" placeholder="请选择时间" />
				<van-icon class="inputIcon" v-show="!hzTime" name="arrow-down" color="#999999" />
				<van-icon  @click.stop="deleteHzTime" class="inputIcon" v-show="hzTime" name="cross" color="#999999" />
			</span>
		</div>
		<div v-else-if="type == 3" class="list-top">
			<span>
				<input v-model="jzrObj.name"  @click="showJzrList" readonly class="ipt ipt4" placeholder="就诊人" />
				<van-icon class="inputIcon" v-show="!jzrObj.name" name="arrow-down" color="#999999" />
				<van-icon  @click.stop="deleteJzrObj" class="inputIcon" v-show="jzrObj.name" name="cross" color="#999999" />
			</span>
			<span>
				<input readonly v-model="hzTypeObj.name" class="ipt ipt4" @click="isShow.hzTypeList=true" placeholder="会诊类型" />
				<van-icon class="inputIcon" v-show="!hzTypeObj.name" name="arrow-down" color="#999999" />
				<van-icon  @click.stop="deleteHzTypeObj" class="inputIcon" v-show="hzTypeObj.name" name="cross" color="#999999" />
			</span>
			<span>
				<input readonly v-model="hzStateObj.name" class="ipt ipt4" @click="isShow.hzStateList=true" placeholder="会诊状态" />
				<van-icon class="inputIcon" v-show="!hzStateObj.name" name="arrow-down" color="#999999" />
				<van-icon  @click.stop="deleteStateObj" class="inputIcon" v-show="hzStateObj.name" name="cross" color="#999999" />
			</span>
		</div>
		<div class="list-container" :style="{ height: 'calc(100% - ' + (type == 1 ? '0px' : '40px') + ')' }">
			<div class="listNull-container" v-if="list.length == 0 && isRequest">
				<img class="listNull" src="../../assets/img/listNull.png" />
				<p class="text">当前暂无新消息</p>
			</div>
			<div class="list" v-else>
				<div class="item" @click="toChatRoom(item)" v-for="item in list">
					<div class="item-left">
						<div class="item-left-content">
							<van-badge :content="item.noRead" :show-zero="false" max="99" :offset="[-10, 10]"><img class="item-img" :src="getHeadImg(item)" /></van-badge>
						</div>
					</div>
					<div class="item-right">
						<div class="item-right-top">
							<p class="item-name">{{ item.roomType == 3? item.roomName:'就诊人：'+ item.name}}</p>
							<span class="item-date">{{ item.createTime }}</span>
						</div>
						<div class="item-message">{{ getMsgText(item.content, item.msgType) }}</div>
						<!-- <div class="item-p">就诊人：{{ item.name }}</div> -->
					</div>
				</div>
			</div>
		</div>

		<!-- 选择就诊人 -->
		<van-popup v-model:show="isShow.jzrList" round position="bottom">
			<van-picker :columns="jzrList" :columns-field-names="{ text: 'name', value: 'id' }" @cancel="isShow.jzrList = false" @confirm="selectJzrObj" />
		</van-popup>
		<!-- 选择会诊类型 -->
		<van-popup v-model:show="isShow.hzTypeList" round position="bottom">
			<van-picker :columns="hzTypeList" :columns-field-names="{ text: 'name', value: 'id' }" @cancel="isShow.hzTypeList = false" @confirm="selectHzType" />
		</van-popup>
		<!-- 选择会诊状态 -->
		<van-popup v-model:show="isShow.hzStateList" round position="bottom">
			<van-picker :columns="hzStateList" :columns-field-names="{ text: 'name', value: 'id' }" @cancel="isShow.hzStateList = false" @confirm="selectHzState" />
		</van-popup>
		<!-- 选择日期 -->
		<van-calendar :min-date="$store.state.minDate" color="#1989fa" v-model:show="isShow.hzTime" @confirm="selectHzTime" />
	</div>
</template>

<script>
import { Badge, Popup, Picker,Calendar,Icon } from 'vant';
const yizhuImg = require('../../assets/img/icon/yizhu.png');
const liaotianshiImg = require('../../assets/img/icon/msgHzIcon.png');
export default {
	name: 'messageList',
	components: {
		[Badge.name]: Badge,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Calendar.name]:Calendar,
		[Icon.name]:Icon,
	},
	props: ['isSelect', 'msgUnreadNum', 'type'],
	data() {
		return {
			msgListActive: 0,
			list: [],
			isRequest: false,
			isShow: {
				jzrList: false,
				hzTypeList: false,
				hzStateList: false,
				hzTime:false,
			},
			// 就诊人列表
			jzrList: [],
			// 会诊类型
			hzTypeList: [{ name: '图文', id: 1 }, { name: '语音', id: 2 }, { name: '视频', id: 3 }],
			// 会诊状态
			hzStateList: [{ name: '待开始', id: 0 }, { name: '进行中', id: 1 }, { name: '已结束', id: 2 }],
			// 选中的就诊人
			jzrObj:{},
			// 选中的会诊类型
			hzTypeObj:{},
			// 选中的会诊状态
			hzStateObj:{},
			// 选择日期
			hzTime:"",
		};
	},
	created() {
		this.getList();
	},
	methods: {
		// 获取会话（聊天室）列表
		getList() {
			let sendform={
				patientId: this.$cache.local.getItem('patientId')
			}
			if(this.type==2){
				sendform.roomTypes=[1,2]
			}else if(this.type==3){
				sendform.roomTypes=[3]
			}
			sendform.seeDoctorId=this.jzrObj.id;
			sendform.roomServerType=this.hzTypeObj.id;
			sendform.roomStatus=this.hzStateObj.id;
			sendform.msgDate=this.hzTime;
			this.$http
				.request('getMsgList', sendform)
				.then(res => {
					console.log(res);
					let list = res || [];
					if (this.type == 1 || this.type == 2) {
						let listA1 = [];
						let listA2 = [];
						// createTime
						// 时间大的在前面
						for (let i in list) {
							list[i].timeNum = this.$comm.getDateNum(list[i].createTime);
							if (list[i].noRead && list[i].noRead > 0) {
								// listA1.unshift(list[i])
								if (listA1.length == 0) {
									listA1.push(list[i]);
								} else if (listA1[listA1.length - 1].timeNum >= list[i].timeNum) {
									listA1.push(list[i]);
								} else {
									for (let j in listA1) {
										if (listA1[j].timeNum <= list[i].timeNum) {
											listA1.splice(j, 0, list[i]);
											break;
										}
									}
								}
							} else {
								// listA2.unshift(list[i])
								if (listA2.length == 0) {
									listA2.push(list[i]);
								} else if (listA2[listA2.length - 1].timeNum >= list[i].timeNum) {
									listA2.push(list[i]);
								} else {
									for (let j in listA2) {
										if (listA2[j].timeNum <= list[i].timeNum) {
											listA2.splice(j, 0, list[i]);
											break;
										}
									}
								}
							}
						}
						this.list = listA1.concat(listA2);
					} else if (this.type == 3) {
						let listB1 = [];
						let listB2 = [];
						let listB3 = [];
						let listB4 = [];
						
						// 进行中 、待开始、会诊完成
						for(let i in list){
							list[i].timeNum = this.$comm.getDateNum(list[i].createTime);
							// 会诊开始时间时间戳 startConsultTime
							list[i].startConsultTimeNum= this.$comm.getDateNum(list[i].startConsultTime);
							if(list[i].noRead && list[i].noRead > 0){
								if (listB1.length == 0) {
									listB1.push(list[i]);
								} else if (listB1[listB1.length - 1].timeNum >= list[i].timeNum) {
									listB1.push(list[i]);
								} else {
									for (let j in listB1) {
										if (listB1[j].timeNum <= list[i].timeNum) {
											listB1.splice(j, 0, list[i]);
											break;
										}
									}
								}
							}else{
								if(list[i].roomStatus==1){
									// 倒序
								if (listB2.length == 0) {
									listB2.push(list[i]);
								} else if (listB2[listB2.length - 1].startConsultTimeNum >= list[i].startConsultTimeNum) {
									listB2.push(list[i]);
								} else {
									for (let j in listB2) {
										if (listB2[j].startConsultTimeNum <= list[i].startConsultTimeNum) {
											listB2.splice(j, 0, list[i]);
											break;
										}
									}
								}
							}else if(list[i].roomStatus==0){
									// 正序
								if (listB3.length == 0) {
									listB3.push(list[i]);
								} else if (listB3[listB3.length - 1].startConsultTimeNum <= list[i].startConsultTimeNum) {
									listB3.push(list[i]);
								} else {
									for (let j in listB3) {
										if (listB3[j].startConsultTimeNum >= list[i].startConsultTimeNum) {
											listB3.splice(j, 0, list[i]);
											break;
										}
									}
								}
							}else if(list[i].roomStatus==2){
									// 倒序
								if (listB4.length == 0) {
									listB4.push(list[i]);
								} else if (listB4[listB4.length - 1].startConsultTimeNum >= list[i].startConsultTimeNum) {
									listB4.push(list[i]);
								} else {
									for (let j in listB4) {
										if (listB4[j].startConsultTimeNum <= list[i].startConsultTimeNum) {
											listB4.splice(j, 0, list[i]);
											break;
										}
									}
								}
							}
							}
						}
						this.list=listB1.concat(listB2,listB3,listB4)
					} else {
						this.list = list;
					}
					// let num = 0;
					// for (let i in this.list) {
					// 	num += this.list[i].noRead;
					// }
					// this.$emit('update:msgUnreadNum', num);
					this.isRequest = true;
				})
				.catch(() => {
					this.isRequest = true;
				});
		},
		// 设置list
		setList(item) {
			for (let i in this.list) {
				if (this.list[i].roomId == item.roomId) {
					this.list[i].content = item.msgContent;
					this.list[i].msgType = item.msgType;
					this.list[i].createTime = item.createTime;
					this.list[i].noRead=item.noRead;
					console.log(this.list[i]);
					let listItem = this.list[i];
					this.list.splice(i, 1);
					this.list.unshift(listItem);
					return;
				}
			}
			// 没有匹配的
			console.error('没有匹配成功---');
		},
		// 新增会诊 --新增咨询不会在这个页面出现
		addList(item) {
			if (item.msgContent) {
				try {
					let msgContent = JSON.parse(item.msgContent);
					// 查看是否存在
					for (let i in this.list) {
						if (this.list[i].mtype != msgContent.mtype) {
							// 已存在
							if (this.list[i].roomId == msgContent.roomId) {
								return;
							}
						}
					}
					this.list.unshift(msgContent);
				} catch {}
			}
		},
		// 跳转至聊天室
		toChatRoom(item) {
			if (item.roomType == '1' || item.roomType == '2') {
				this.$router.push({
					path: '/yizhuChatRoom',
					query: {
						seeDoctorId: item.seeDoctorId,
						id: item.roomId
					}
				});
			} else {
				// 会诊
				this.$router.push({
					path: '/chatRoom',
					query: {
						seeDoctorId: item.seeDoctorId,
						roomId: item.roomId
					}
				});
			}
		},
		// 获取显示文字内容
		getMsgText(l, t) {
			// 1文字 2图片 3订单支付 4成功预约 5 推荐医生6文件 7视频 8音频 9类似系统消息
			if (t == 1 || t == 9) {
				return l;
			} else if (t == 2) {
				return '[图片]';
			} else if (t == 3) {
				return '[订单支付]';
			} else if (t == 4) {
				return '[成功预约]';
			} else if (t == 5) {
				return '[推荐医生]';
			} else if (t == 6) {
				return '[文件]';
			} else if (t == 7) {
				return '[视频]';
			} else if (t == 8) {
				return '[音频]';
			} else if (t == 11) {
				return '[支付成功]';
			} else if (t == 12) {
				return '[会诊结束]';
			} else if (t == 13) {
				return '[病情概述]';
			}
		},
		// 全部标记为已读
		readAll() {
			for (let i in this.list) {
				this.list[i].noRead = 0;
			}
		},
		// 获取头像
		getHeadImg(item) {
			if (item.roomType == 1 || item.roomType == 2) {
				return item.accountPictureUrl || yizhuImg;
			} else {
				return item.roomHeadImg || liaotianshiImg;
			}
		},
		// 显示就诊人弹框
		showJzrList(){
			this.getJzrList();
			this.isShow.jzrList=true;
		},
		// 获取就诊人列表
		getJzrList(){
      this.$http
        .request("queryPatList", {
          patientId: this.$cache.local.getItem("patientId"),
        })
        .then((res) => {
          console.log(res, "就诊人列表");
          this.jzrList=res||[];
        })
        .catch((e) => {
          console.log(e);
        });
    },
	// 选中就诊人
	selectJzrObj(item){
		this.jzrObj=item;
		this.isShow.jzrList=false;
		this.getList()
	},
	// 选中会诊类型
	selectHzType(item){
		this.hzTypeObj=item;
		this.isShow.hzTypeList=false;
		this.getList()
	},
	// 选中会诊类型
	selectHzState(item){
		this.hzStateObj=item;
		this.isShow.hzStateList=false;
		this.getList()
	},
	// 选择日期
	selectHzTime(i){
		this.hzTime=this.$comm.getDate(i,1)
		this.isShow.hzTime=false;
		this.getList();
	},
	// 清空就诊人
	deleteJzrObj(){
		this.jzrObj={}
		this.getList();
	},
	// 清空会诊类型
	deleteHzTypeObj(){
		this.hzTypeObj={}
		this.getList();
	},
	// 清空会诊状态
	deleteStateObj(){
		this.hzStateObj={};
		this.getList();
	},
	// 清空日期
	deleteHzTime(){
		this.hzTime="";
		this.getList();
	},
	
	},
	watch: {
		// 没有触发
		'$store.state.wsMsgList': {},
		// 监听wsmsgList更改
		'$store.state.wsMsgNum'() {},
		// 监听ws最新消息
		'$store.state.wsNewMsg'(item) {
			console.log(item, '监听最新消息');
			if (item) {
				this.setList(item);
			}
		},
		// 监听新增咨询消息
		'$store.state.addNewZx'(item) {
			if (item&&this.type==2) {
				this.addList(item);
			}
		},
		// 监听新增会诊消息
		'$store.state.addHzRoom'(item) {
			if (item&&this.type==3) {
				this.addList(item);
			}
		},
		isSelect(t) {
			if (t) {
				this.getList();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.TypemsgListView {
}
.list-top {
	width: 100%;
	padding: 10px 0;
	display: flex;
	justify-content: space-around;
	> span {
		position: relative;
		padding-right: 20px;
		.inputIcon{
			width: 20px;
			height: 20px;
			line-height: 20px;
			position: absolute;
			font-size: 16px;
			top: 0;
			right: 0;
		}
	}
	.ipt {
		height: 20px;
		font-size: 15px;
		border: 0;
	}
	.ipt4 {
		width: 4em;
	}
	.ipt6 {
		width: 6em;
	}
}
.list-container {
	overflow: auto;
	.list {
		padding: 0 16px;
		.item {
			display: flex;
			height: 60px;
			padding: 15px 0;
			border-bottom: 1px solid #eeeeee;
			width: 100%;
			.item-left {
				width: 48px;
				display: flex;
				align-items: center;
				.item-left-content {
					width: 48px;
					height: 48px;
				}
			}
			.item-right {
				padding-left: 15px;
				width: calc(100% - 63px);
				.item-right-top {
					// line-height: 38px;
					margin-bottom: 6px;
					display: flex;
					justify-content: space-between;
					width: 100%;
					.item-name {
						font-weight: 600;
						color: #333333;
						font-size: 16px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						width: calc(100% - 130px);
					}
					.item-date {
						font-size: 12px;
						font-weight: 400;
						color: #999999;
						width: 120px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}
				.item-message {
					font-weight: 400;
					color: #999999;
					line-height: 24px;
					font-size: 14px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					width: 100%;
				}
				.item-p {
					font-weight: 400;
					color: #278eff;
					line-height: 20px;
					font-size: 14px;
				}
			}
			.item-img {
				width: 48px;
				height: 48px;
				border-radius: 50%;
			}
		}
	}
}
.listNull-container {
	text-align: center;
	.listNull {
		width: 135px;
		margin-top: 20vh;
	}
	.text {
		font-size: 14px;
		font-weight: 400;
		color: #278eff;
		margin-top: 4px;
		line-height: 20px;
	}
}
</style>
