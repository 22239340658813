/* 存储管理 */
let encode = function (str) {
  if (!str) {
    return str;
  }
  let b = window.btoa(str);
  return b;
};

// base64转字符串
let decode = function (b) {
  if (!b) {
    return b;
  }
  // 对base64转编码
  let d = window.atob(b);
  return d;
};
// --此值不可更改！！！
const STORAGE_PREFIX = "hzb_brd_";
// token,登录人信息
//toConsultInfo 立即咨询信息，新增就诊人 返回需要回显数据

const keyList = [
  "token",
  "patientId",
  "companyId",
  "organizationName",
  "addrId",
  "addrName",
  "appid",
  "toConsultInfo",
  "searchInput",
  "searchInputHos",
  "locationCity",
  "isLocationCity",
  "consultPagePatientId",
]; //可以在此声明使用的缓存，如不声明提示错误
// 会话存储 window.sessionStorage
function mySession() {
  this.setItem = function (name, content) {
    if (keyList.indexOf(name) == -1) {
      console.error(name + ":未声明，来自缓存管理，请在keyList中添加" + name);
      return;
    }
    if (typeof content == "object") {
      content = JSON.stringify(content);
    } else {
      content = String(content);
    }
    window.sessionStorage.setItem(STORAGE_PREFIX + name, content);
  };
  this.getItem = function (name, type) {
    try {
      if (type == "JSON" || type == "json") {
        if (window.sessionStorage.getItem(STORAGE_PREFIX + name)) {
          return JSON.parse(
            window.sessionStorage.getItem(STORAGE_PREFIX + name)
          );
        } else {
          return {};
        }
      } else {
        return window.sessionStorage.getItem(STORAGE_PREFIX + name);
      }
    } catch {
      return window.sessionStorage.getItem(STORAGE_PREFIX + name);
    }
  };
  this.removeItem = function (name) {
    window.sessionStorage.removeItem(STORAGE_PREFIX + name);
  };
  this.clear = function () {
    window.sessionStorage.clear();
  };
}
let session = new mySession();

// 本地存储 localStorage
function localSession() {
  this.setItem = function (name, content) {
    if (keyList.indexOf(name) == -1) {
      console.error(name + ":未声明，来自缓存管理，请在keyList中添加" + name);
      return;
    }
    if (typeof content == "object") {
      content = JSON.stringify(content);
    } else {
      content = String(content);
    }
    window.localStorage.setItem(STORAGE_PREFIX + name, content);
  };
  this.getItem = function (name, type) {
    try {
      if (type == "JSON" || type == "json") {
        if (window.localStorage.getItem(STORAGE_PREFIX + name)) {
          return JSON.parse(window.localStorage.getItem(STORAGE_PREFIX + name));
        } else {
          return {};
        }
      } else {
        return window.localStorage.getItem(STORAGE_PREFIX + name);
      }
    } catch {
      return window.localStorage.getItem(STORAGE_PREFIX + name);
    }
  };
  this.removeItem = function (name) {
    window.localStorage.removeItem(STORAGE_PREFIX + name);
  };
  this.clear = function () {
    window.localStorage.clear();
  };
}

let local = new localSession();

// 本地存储
// cookie存储
/* 
  可优化->如果有同时获取多个的 cname 可更改为数组 返回 数组
  也可进行对cookie的管控 如果未声明不可添加 如 session
 */
function myCookie() {
  this.setItem = function (cname, cvalue, exdays = 7) {
    cvalue = encode(cvalue);
    //名字 内容 时间
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toGMTString();
    document.cookie = STORAGE_PREFIX + cname + "=" + cvalue + "; " + expires;
  };
  this.getItem = function (name) {
    let strcookie = document.cookie; //获取cookie字符串
    let arrcookie = strcookie.split("; "); //分割 //遍历匹配
    for (let i = 0; i < arrcookie.length; i++) {
      let arr = arrcookie[i].split("=");
      if (arr[0] == STORAGE_PREFIX + name) {
        console.log(arr[1], decode);
        return decode(arr[1]);
      }
    }
    return "";
  };
  this.removeItem = function (cname) {
    //名字 内容 时间
    document.cookie = STORAGE_PREFIX + cname + "=''; ";
  };
  this.clear = function () {
    let strcookie = document.cookie; //获取cookie字符串
    let arrcookie = strcookie.split("; "); //分割 //遍历匹配
    for (let i = 0; i < arrcookie.length; i++) {
      let arr = arrcookie[i].split("=");
      document.cookie = arr[0] + "=''; ";
    }
  };
}
let cookie = new myCookie();

// 存储缓存 setItem
// 获取缓存 getItem
// 清空缓存 removeItem
// 清空所有缓存 clear

// 获取房间文字信息
function getRoomInputValue(roomId) {
  return (
    window.sessionStorage.getItem(STORAGE_PREFIX + "inputvalue_" + roomId) || ""
  );
}
// 设置房间文字信息
function setRoomInputValue(roomId, val) {
  window.sessionStorage.setItem(
    STORAGE_PREFIX + "inputvalue_" + roomId,
    val || ""
  );
}

export default {
  session,
  local,
  cookie,
  getRoomInputValue,
  setRoomInputValue,
};
