import axios from "axios";
import qs from "qs";
import httpData from "./httpData.js";
import router from "../router";
import { Dialog, Notify, Toast } from "vant";
import cache from "../utils/storage.js";
import myWs from "../utils/ws.js";
import { changhttp } from "../utils/comm.js";
// 当前账号的权限 获取缓存数据
const jurisdiction = [1, 2, 3, 4, 5, 6, 7, 8];
const UrlObj = httpData.returnUrl;
// 线上地址
let baseURL = UrlObj.Url;
if (process.env.NODE_ENV == "development") {
  // 开发环境
  baseURL = UrlObj.DEV_URL;
}
// 创建axios实例
const service = axios.create({
  baseURL: baseURL,
  timeout: 50000,
  // headers: { "content-type": "application/json" }
  // withCredentials: true
});
const httpPorts = httpData.httpPorts;
/* 请求终止函数集合 */
const cancelTokenArr = [];
// 退出登录
function outLogin() {
  // 跳转至html页面 不用再取消请求了
  // cancelTokenArr.forEach((item) => {
  //   item('路由跳转取消请求')
  // })
  // 清除缓存
  cache.local.removeItem("token");
  cache.local.removeItem("patientId");
  cache.local.removeItem("companyId");
  cache.local.removeItem("addrId");
  cache.local.removeItem("addrName");
  // 关闭全局ws
  myWs.close();
  console.log("退出跳转");
  window.location.href = "./loginPage.html";
}
// 成功code处理
function codeDispose(res, u) {
  if (!res.data) {
    console.error("请求错误：" + name + ">>" + u.url);
  }
  // 警告
  if (res.data.code == "301") {
    if (
      !res.data.msg ||
      res.data.msg == "err" ||
      res.data.msg == "error" ||
      res.data.msg == "erro"
    ) {
      if (!u.noAlert) {
        Toast(u.name + "失败");
      }
    } else {
      if (!u.noAlert) {
        Toast(res.data.msg);
      }
    }
  } else if (res.data.code == "10001") {
    // 退出登录
    if (cache.local.getItem("token")) {
      cache.local.removeItem("token");
      console.log("您的登录已超时，请您重新登录");
      Dialog.alert({
        message: "您的登录已超时，请重新登录",
      }).then(() => {
        outLogin();
      });
    } else {
      outLogin();
    }
  } else if (res.data.code == "500") {
    if (
      !res.data.msg ||
      res.data.msg == "err" ||
      res.data.msg == "error" ||
      res.data.msg == "erro"
    ) {
      // ElMessage({
      // 	title: "错误",
      // 	message: u.name + "失败",
      // 	customClass: "maxZindex",
      // 	type: 'error'
      // });
      if (!u.noAlert) {
        Toast(u.name + "失败");
      }
    } else {
      if (!u.noAlert) {
        Toast(res.data.msg);
      }

      // ElMessage({
      // 	title: "错误",
      // 	customClass: "maxZindex",
      // 	message: res.data.msg,
      // 	type: 'error'
      // });
    }
  } else {
    if (!u.noAlert) {
      Toast(res.data.msg);
    }
  }
}
// 全局等待
const loading = "";
// 权限管理函数
// jurisdiction.u.jurisdiction;
// z 账号权限 u 请求接口接口所需要的权限
function returnIsJurisdiction(z, u) {
  if (!u) {
    u = [1, 2, 3, 4, 5, 6, 7];
  }
  for (const i in z) {
    for (const j in u) {
      if (z[i] == u[j]) {
        return true;
      }
    }
  }
  return false;
}
/* 整合至一个接口 */
function request(name, param = {}, data) {
  // urlObj
  return new Promise((resolve, reject) => {
    if (!httpPorts[name]) {
      console.error("name:" + name + ",无对应的接口数据，请检查");
      return reject({
        code: -9999,
      });
    }
    const u = { ...httpPorts[name] };
    // console.log(u.name);
    // 权限管理
    if (!returnIsJurisdiction(jurisdiction, u.jurisdiction)) {
      // Message({
      // 	message: "警告，权限不足",
      // 	type: "warning",
      // });
      return reject({
        code: -9998,
      });
    }
    if (u.isDownload || u.isLoading) {
      // 开始下载
      // loading = Loading.service({
      // 	lock: true,
      // 	text: u.loadingText || "正在导出",
      // 	spinner: "el-icon-loading",
      // 	customClass: "maxZindex",
      // 	//        background: 'rgba(0, 0, 0, 0.7)' 2147483647
      // });
    }
    const headers = u.headers || {
      "Content-Type": "application/json;charset=utf-8",
    };
    headers.authType = 2;
    if (u.isToken !== 0) {
      if (!cache.local.getItem("token")) {
        outLogin();
        return reject({
          code: -9998,
        });
      } else {
        headers.Authorization = cache.local.getItem("token");
      }
    }
    if (u.urlAfter) {
      for (let i in u.urlAfter) {
        u.url += "/" + param[u.urlAfter[i]];
        delete param[u.urlAfter[i]];
      }
    }
    // 是否去掉空字符 fallNUL
    if (u.fallNUL === true) {
      for (const fi in param) {
        if (param[fi] === "") {
          delete param[fi];
        }
      }
    }

    // console.log(u.url, param, headers);
    // 其他配置
    const elsePZ = {
      headers: headers,
      timeout: u.isDownload ? 0 : 5 * 60 * 1000,
      // onDownloadProgress: function (ProgressEvent) {
      //   // console.log("监听上传进度", ProgressEvent);
      //   if (u.isDownload) {
      //     // const load = ProgressEvent.loaded;
      //     // const total = ProgressEvent.total;
      //     // const progress = (load / total) * 100;
      //     // console.log(ProgressEvent, progress);
      //     // bar.style.width = progress - 1 + "%";
      //   }
      // },
      // withCredentials: true,
      responseType: u.isDownload ? "blob" : "json", // important
    };
    // console.log(elsePZ, "其他参数");
    if (u.method == "POST" || u.method == "post") {
      requestPost(u, param, elsePZ, resolve, reject, data);
    } else if (u.method == "GET" || u.method == "get" || !u.method) {
      requestGet(u, param, elsePZ, resolve, reject);
    }
  });
}
// get请求
function requestGet(u, params, elsePZ, resolve, reject) {
  params = qs.stringify(params);
  // console.log(`${u.url}?${params}`, elsePZ.headers);
  // return reject({});
  //  { headers: elsePZ.headers }
  service
    .get(`${u.url}?${params}`, elsePZ)
    .then((response) => {
      successJX(u, response, resolve, reject);
    })
    .catch((error) => {
      errJX(u, error, reject);
    });
}
// post请求
function requestPost(u, params, elsePZ, resolve, reject, data) {
  let cs = ""; // {a:b,c:d} ?a=b&a=c
  let dataParams = params;
  if (data || data === "") {
    dataParams = data;
    if (params) {
      for (const i in params) {
        if (cs) {
          cs += "&";
        } else {
          cs += "?";
        }
        cs += `${i}=${params[i]}`;
      }
    }
  }
  if (u.stringify === true) {
    dataParams = qs.stringify(dataParams);
  }
  if (u.isString) {
    cs = params;
    dataParams = undefined;
  }
  // console.log(u.url, cs, "-----", dataParams, "--------post", elsePZ);
  service
    .post(u.url + cs, dataParams, elsePZ)
    .then((res) => {
      successJX(u, res, resolve, reject);
    })
    .catch((error) => {
      errJX(u, error, reject);
    });
}
// 请求返回解析--也可写拦截器
// 成功
function successJX(u, res, resolve, reject) {
  if (!u.isDownload) {
    if (u.isLoading) {
      // loading.close();
    }
    if (!res.data) {
      codeDispose(res, u);
      return reject({
        code: -9999,
        msg: "获取数据失败",
        data: null,
      });
    }
    /* 成功 SUCCESS(200),
	  警告 WARN(301),
	  错误 ERROR(500);
	  登录超时 10001
	  */
    if (res.data.code == "200") {
      // 成功
      return resolve(res.data.data);
    } else {
      console.log(res, "其他状态");
      if (u.successCodeName) {
        if (
          res.data &&
          res.data[u.successCodeName] &&
          u.successCodeKeys.indexOf(res.data[u.successCodeName]) != -1
        ) {
          return resolve(res.data.data);
        }
      }
      codeDispose(res, u);
      return reject(res.data);
    }
  } else {
    // 下载
    // console.log(res,'下载数据',typeof res.data)
    if (res.data) {
      if (res.data.code) {
        // 下载报错
        // loading.close();
        codeDispose(res, u);
        // 错误处理
        reject(res);
      } else {
        // console.log("aaa");
        // 开始下载
        const link = document.createElement("a");
        const blob = new Blob([res.data], {
          type: u.blobType || "application/vnd.ms-excel",
        });
        // xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        //  zip: 'application/zip'

        // 获取heads中的filename文件名
        // let temp ='aaa';
        // 对文件名乱码转义--【Node.js】使用iconv-lite解决中文乱码
        // let iconv = require("iconv-lite");
        // iconv.skipDecodeWarning = true; //忽略警告
        // let fileName = iconv.decode(temp, 'gbk');
        // return
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        // link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        // 下载成功
        document.body.removeChild(link);
        // loading.close();
        resolve(res.data.data);
      }
    }
  }
}

function errJX(u, err, reject) {
  if (u.isDownload || u.isLoading) {
    // loading.close();
  }
  // 网络异常处理
  console.log("网络异常", err, u);
  let message = "";
  if (err && err.response && err.response.status) {
    switch (err.response.status) {
      case 400:
        message = "请求错误(400)";
        break;
      case 401:
        message = "未授权，请重新登录(401)";
        break;
      case 403:
        message = "拒绝访问(403)";
        break;
      case 404:
        message = "请求出错(404)";
        break;
      case 408:
        message = "请求超时(408)";
        break;
      case 500:
        message = "服务器错误(500)";
        break;
      case 501:
        message = "服务未实现(501)";
        break;
      case 502:
        message = "网络错误(502)";
        break;
      case 503:
        message = "服务不可用(503)";
        break;
      case 504:
        message = "网络超时(504)";
        break;
      case 505:
        message = "HTTP版本不受支持(505)";
        break;
      default:
        message = `连接出错(${err.response.status})!`;
    }
  } else {
    message = "连接失败!";
  }
  if (!u.noAlert) {
    Notify({
      type: "danger",
      message: u.name + "（" + message + "）",
    });
  }

  return reject(err);
}
// 文件下载--阿里云等模式=a链接下载
function downloadFile(loadUrl, name) {
  loadUrl = changhttp(loadUrl);
  return new Promise((resolve, reject) => {
    service
      .get(loadUrl)
      .then((res) => {
        let link = document.createElement("a");
        let blob = new Blob([res.data]);
        //获取heads中的filename文件名
        // let temp ='aaa';
        //对文件名乱码转义--【Node.js】使用iconv-lite解决中文乱码
        // let iconv = require("iconv-lite");
        // iconv.skipDecodeWarning = true; //忽略警告
        // let fileName = iconv.decode(temp, 'gbk');
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        // console.log(URL.createObjectURL(blob), blob)
        link.setAttribute("download", name || undefined);
        document.body.appendChild(link);
        link.click();
        //下载成功
        document.body.removeChild(link);
        resolve();
      })
      .catch((error) => {
        console.error(error);
        Notify({
          type: "danger",
          message: "下载失败",
        });
        reject();
      });
  });
}
export default {
  http: UrlObj.Url,
  request,
  httpData: httpData,
  downloadFile,
};
