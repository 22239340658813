<template>
  <!-- <div>
    首页
    <button @click="venice">立即咨询接口测试</button>
  </div> -->
  <div class="home-wrapper">
    <div class="phone-box">
      <div class="top-bg">
        <div class="top-logo">
          <img src="../../assets/logo-white.png" alt="" />
          <van-field
            v-if="isAreType == 1"
            v-model="locationCity"
            is-link
            readonly
            placeholder="选择城市"
            @click="showPicker = true"
          />
          <span v-else class="hospital-name">{{ hospitalName }}</span>
        </div>
        <div class="hospital-box">
          <van-search
            v-model="searchInput"
            placeholder="医生/科室"
            background="transparent"
            shape="round"
            show-action
            @search="onClickButton"
          >
            <template #action>
              <div @click="onClickButton" style="color: #fff">搜索</div>
            </template>
          </van-search>
        </div>
        <div class="area-type">
          <div class="area1" v-if="isAreType == 1">
            <div class="area1-item" @click="venice">
              <img src="../../assets/img/ar1.png" alt="" />
              <span class="text1">在线咨询</span>
              <span class="text2"
                >专业及时 <br />
                的导医服务</span
              >
            </div>
            <div class="area1-item" @click="toDoctorArea">
              <img src="../../assets/img/ar2.png" alt="" />
              <span class="text1">找名医</span>
              <span class="text2"
                >1万+医生 <br />
                在线服务</span
              >
            </div>
            <div class="area1-item" @click="toHospital">
              <img src="../../assets/img/ar3.png" alt="" />
              <span class="text1">找医院</span>
              <span class="text2"
                >全国上 <br />
                百所医院</span
              >
            </div>
          </div>
          <div class="area2" v-else>
            <div class="area2-item" @click="venice">
              <img src="../../assets/img/bg1.png" alt="" />
              <span class="text11 col1">在线咨询</span>
              <span class="text22">专业及时的导医服务</span>
            </div>
            <div class="area2-item" @click="toDoctorHospital">
              <img src="../../assets/img/bg2.png" alt="" />
              <span class="text11 col2">找名医</span>
              <span class="text22">1万+医生在线服务</span>
            </div>
          </div>
        </div>
      </div>
      <div class="banner-list">
        <van-swipe
          v-if="bannerList.length != 0"
          class="my-swipe"
          :autoplay="3000"
          indicator-color="white"
        >
          <van-swipe-item
            v-for="item in bannerList"
            :key="item.id"
            @click="toHref(item)"
          >
            <img :src="item.content" />
          </van-swipe-item>
        </van-swipe>
        <div v-else class="empty-text-center">暂无banner图</div>
      </div>
      <div>
        <div class="icon-title depart">
          <img src="../../assets/ti.png" alt="" />
          <span>专科服务</span>
        </div>
        <div v-if="departData.length != 0" class="depart-type">
          <!-- 判断条件记得改 -->
          <div
            v-for="item in departData"
            :key="item.id"
            class="depart-item"
            @click="toDepIdDoctor(item)"
          >
            <div v-if="item.departmentAliasName" class="depart-item-con">
              <img
                v-if="item.departmentIconImg"
                :src="item.departmentIconImg"
                alt=""
              />
              <img v-else src="../../assets/img/ks.png" alt="" />
              {{ item.departmentAliasName }}
            </div>
          </div>
          <div
            v-if="departData.length >= 9"
            class="depart-item"
            @click="lookMore"
          >
            <div class="depart-item-con">
              <img src="../../assets/img/more.png" alt="" />
              查看全部
            </div>
          </div>
        </div>
        <div v-else class="empty-text">暂无专病服务</div>
      </div>
      <div class="doctor-list">
        <div class="icon-title title-box">
          <div>
            <img src="../../assets/ti.png" alt="" />
            <span>专家推荐</span>
          </div>
          <div class="more-doc" @click="toDoctorList">更多专家 ></div>
        </div>

        <div v-if="doctorData.length != 0">
          <div
            v-for="item in doctorData"
            :key="item.id"
            class="doctor-item"
            @click="toDetail(item)"
          >
            <van-image
              v-if="item.doctorHeadImg"
              width="50"
              height="50"
              fit="contain"
              round
              :src="item.doctorHeadImg"
            />
            <van-image
              v-else
              width="50"
              height="50"
              fit="contain"
              round
              src="http://maicc-test.oss-cn-beijing.aliyuncs.com/temp-folder/2023-02-01%5Cf5a4f8e4-4003-454f-84a1-4603d722ecd6-6a48ae42-04da-4b8a-9fe2-af62b1e4fc99.png"
            />
            <div class="doctor-rgt">
              <p class="rgt-one">
                <span>
                  <span class="name">{{ item.doctorName }}</span>
                  <span class="text"
                    >{{
                      // item.departmentName
                      //   ? item.departmentName.split(",")[0]
                      //   : ""
                      item.jobDepartmentName
                    }}
                    |
                    {{
                      convertDoctorProfessional(item.doctorProfessional)
                    }}</span
                  >
                  <span v-if="item.isDoctorTeam == 1" class="team-box"
                    >团队</span
                  >
                </span>

                <span>
                  <van-icon
                    v-if="item.isFollow == 1"
                    name="star"
                    color="#F79C42"
                    @click.stop="cancelFollowDoctor(item)"
                  />
                  <van-icon
                    v-else
                    name="star"
                    color="#E2E1E4"
                    @click.stop="followDoctor(item)"
                  />
                </span>
              </p>
              <p class="doctor-text">{{ item.doctorGood }}</p>
              <p>
                <span
                  class="doctor-tag"
                  v-for="item2 in item.dptTags"
                  :key="item2.id"
                  >{{ item2.roleName }}</span
                >
              </p>
            </div>
          </div>
        </div>
        <div v-else class="empty-text">暂无专家推荐</div>
      </div>
    </div>

    <div class="dw-bottom">
      <div v-for="item in hzList" class="hzItem">
        <div
          v-if="item.roomStatus == 0"
          @click="toRoomOrorder(item)"
          class="bot-msg bot-msg-vol1"
        >
          <span> 您有会诊即将开始 </span>
          <span class="zixun">
            查看详情
            <img
              src="../../assets/img/lft2-white.png"
              alt=""
              style="width: 20px"
            />
          </span>
        </div>
        <div
          v-else-if="item.roomStatus == 1"
          @click="toRoomOrorder(item)"
          class="bot-msg bot-msg-vol2"
        >
          <span> 您有会诊正在进行中 </span>
          <span class="zixun">
            立即参与
            <img
              src="../../assets/img/lft2-white.png"
              alt=""
              style="width: 20px"
            />
          </span>
        </div>
        <!-- 2已结束 -->
      </div>
    </div>
  </div>
  <!-- <van-popup v-model:show="showPicker" round position="bottom">
    <van-picker
      :columns="hospitalList"
      :columns-field-names="{ text: 'name', value: 'id' }"
      @cancel="showPicker = false"
      @confirm="onConfirm"
    />
  </van-popup> -->
  <van-popup
    v-model:show="showPicker"
    round
    position="bottom"
    :lazy-render="false"
  >
    <van-picker
      ref="areaPicker"
      :columns="hospitalList"
      :columns-field-names="{ text: 'name', value: 'id' }"
      @cancel="showPicker = false"
      @confirm="onConfirm"
    />
  </van-popup>
</template>

<script>
import {
  PullRefresh,
  Search,
  Picker,
  Popup,
  Field,
  Swipe,
  SwipeItem,
  Icon,
  Image,
  Dialog,
  Notify,
  Toast,
} from "vant";
export default {
  name: "homePage",
  components: {
    [PullRefresh.name]: PullRefresh,
    [Search.name]: Search,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Dialog.name]: Dialog,
    [Notify.name]: Notify,
  },
  data() {
    return {
      isLoading: false,
      count: 0,
      searchInput: "",
      // 就诊人
      patList: [],
      isShow: {
        patList: false,
      },
      //   ----------
      isAreType: 1,
      bannerList: [],
      departData: [],
      doctorData: [],
      hospitalList: [],
      showPicker: false,
      locationCity: "上海市",
      hospitalName: "",
      hospitalId: "",
      hzList: [],
    };
  },
  props: ["isSelect"],
  watch: {
    locationCity: {
      handler: function () {
        this.getCityOrgan();
      },
      // immediate:true
    },
    isSelect: {
      handler: function () {
        if (this.isSelect) {
          this.gethzList();
        }
      },
      immediate: true,
    },
    "$store.state.changeRoomTypeObj"(item) {
      if (this.isSelect) {
        this.changeHzList(item);
      }
    },
    "$store.state.changeYuyueObj"(item) {
      Toast("您有会诊，会诊时间已修改");
      console.log("会诊时间修改", item);
      if (item.msgContent) {
        try {
          let m = JSON.parse(item.msgContent);
          for (let i in this.hzList) {
            if (this.hzList[i].id == m.id) {
              console.error(m.startConsultTime, new Date().getTime());
              if (
                m.startConsultTime &&
                m.startConsultTime > new Date().getTime()
              ) {
                this.hzList[i].roomStatus = 0;
              } else {
                this.hzList[i].roomStatus = 1;
              }

              return;
            }
          }
          // ---没有匹配到
          console.log("没有匹配到");
          this.gethzList();
        } catch {}
      }
    },
  },
  beforeDestroy() {
    // clearInterval(this.timer); //清除定时器
  },
  created() {
    // console.log(this.$cache.session.getItem("addrName"));
    if (this.$cache.local.getItem("appid") == this.$store.state.appid) {
      this.isAreType = 1;
      this.getLngLatLocation();
      this.getHospitalList();
    } else {
      this.isAreType = 2;
      this.hospitalName = this.$cache.local.getItem("organizationName");
	  this.hospitalId=this.$cache.local.getItem("companyId");
	  this.getQueryCityPageJg();
    }
  },
  mounted() {},
  methods: {
    toHref(item) {
      console.log(item, "itemitemitem");
      window.location.href = item.goLink;
    },
    // 搜索
    onClickButton() {
      console.log(this.searchInput, "搜索");
      this.$cache.session.setItem("searchInput", this.searchInput);
      this.$cache.session.setItem("searchInputHos", this.searchInput);
      if (this.isAreType == 1) {
        // this.$router.push("/doctorPageArea?searchInput=" + this.searchInput);
        this.$router.push(
          "/doctorPageArea?locationCity=" +
            this.locationCity +
            "&isAreType=" +
            this.isAreType +
            "&searchInput=" +
            this.searchInput
        );
      } else {
        // this.$router.push(
        //   "/doctorPageHospital?searchInput=" + this.searchInput
        // );
        this.$router.push(
          "/doctorPageHospital?hospitalId=" +
            this.hospitalId +
            "&isAreType=" +
            this.isAreType +
            "&searchInput=" +
            this.searchInput
        );
      }
    },
    // 点击科室
    toDepIdDoctor(item) {
      this.$cache.session.setItem("searchInput", this.searchInput);
      this.$cache.session.setItem("searchInputHos", this.searchInput);
      if (this.isAreType == 1) {
        // 区域
        this.$router.push(
          "/doctorPageArea?locationCity=" +
            this.locationCity +
            "&departId=" +
            item.id +
            "&departName=" +
            item.departmentAliasName +
            "&isAreType=" +
            this.isAreType
        );
      } else {
        // 机构
        this.$router.push(
          "/doctorPageHospital?hospitalId=" +
            this.hospitalId +
            "&departId=" +
            item.id +
            "&departName=" +
            item.departmentAliasName +
            "&isAreType=" +
            this.isAreType
        );
      }
    },
    // 查看全部科室
    lookMore() {
      if (this.isAreType == 1) {
        this.$router.push(
          "/departmentAll?locationCity=" +
            this.locationCity +
            "&isAreType=" +
            this.isAreType
        );
      } else {
        this.$router.push(
          "/departmentAll?hospitalId=" +
            this.hospitalId +
            "&isAreType=" +
            this.isAreType
        );
      }
    },
    // 更多专家
    toDoctorList() {
      this.$cache.session.setItem("searchInput", this.searchInput);
      this.$cache.session.setItem("searchInputHos", this.searchInput);
      if (this.isAreType == 1) {
        this.$router.push("/doctorPageArea?locationCity=" + this.locationCity);
      } else {
        this.$router.push("/doctorPageHospital?hospitalId=" + this.hospitalId);
      }
    },
    // 定位
    getLngLatLocation() {
      if (this.$cache.session.getItem("addrName")) {
        this.locationCity = this.$cache.session.getItem("addrName");
        this.getCityPageData(this.locationCity);
        return;
      }
      let _this = this;
      AMap.plugin("AMap.CitySearch", function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function (status, result) {
          if (status === "complete" && result.info === "OK") {
            // 查询成功，result即为当前所在城市信息
            console.log("获取当前城市：", result);
            _this.locationCity = result.city;
            // result.city = "长沙市";
            _this.getCityPageData(result.city);
          }
        });
      });
    },
    // 根据定位的市查数据
    getCityPageData(name) {
      this.$http
        .request("queryCityPage", {
          name,
          patientId: this.$cache.local.getItem("patientId"),
        })
        .then((res) => {
          if (res) {
            this.bannerList = res.pageBanners;
            this.departData = res.departments;
            this.doctorData = res.doctors;
          } else {
            Dialog.confirm({
              title: "温馨提示",
              message: `${name}暂无数据,请转到上海市查看`,
              showCancelButton: false,
              theme: "round-button",
              confirmButtonColor: "#1989fa",
            })
              .then(() => {
                this.locationCity = "上海市";
                this.getCityPageData("上海市");
              })
              .catch(() => {
                // on cancel
              });
          }
        })
        .catch(() => {});
    },
	// 根据机构id获取数据
	getQueryCityPageJg(){
      this.$http
        .request("queryCityPage", {
          organizationId:this.$cache.local.getItem("companyId"),
          patientId: this.$cache.local.getItem("patientId"),
        })
        .then((res) => {
          if (res) {
            this.bannerList = res.pageBanners;
            this.departData = res.departments;
            this.doctorData = res.doctors;
          } else {
            Dialog.confirm({
              title: "温馨提示",
              message: `该机构暂无数据`,
              showCancelButton: false,
              theme: "round-button",
              confirmButtonColor: "#1989fa",
            })
              .then(() => {})
              .catch(() => {});
          }
        })
        .catch(() => {});
    },
    // 找名医-区域
    toDoctorArea() {
      this.$cache.session.setItem("searchInput", this.searchInput);
      this.$cache.session.setItem("searchInputHos", this.searchInput);
      this.$router.push(
        "/doctorPageArea?locationCity=" +
          this.locationCity +
          "&isAreType=" +
          this.isAreType
      );
    },
    // 找医院
    toHospital() {
      this.$cache.session.setItem("searchInput", this.searchInput);
      this.$cache.session.setItem("searchInputHos", this.searchInput);
      this.$router.push(
        "/hospitalList?locationCity=" +
          this.locationCity +
          "&isAreType=" +
          this.isAreType
      );
    },
    // 找名医-医院
    toDoctorHospital() {
      this.$cache.session.setItem("searchInput", this.searchInput);
      this.$cache.session.setItem("searchInputHos", this.searchInput);
      this.$router.push(
        "/doctorPageHospital?hospitalId=" +
          this.hospitalId +
          "&isAreType=" +
          this.isAreType
      );
    },

    // 机构弹窗确认
    onConfirm(selectedOptions) {
      this.showPicker = false;
      this.locationCity = selectedOptions[1].name;
      this.getCityPageData(selectedOptions[1].name);
    },
    // 获取区域\机构列表
    getHospitalList() {
      this.$http
        .request("queryMapsArea", {
          // addrMapsId: 1,
        })
        .then((res) => {
          // res.forEach((item) => {
          //   item.name = item.map.name;
          // });
          // 后台返回字段有disabled（是否启用服务），关键字，会把picker选项禁用
          res.forEach((item, index) => {
            item.children = item.addrMaps;
            console.log(item.children, "item.children");
            item.children.forEach((city, idx) => {
              console.log(this.locationCity, city.name, "city.name");
              if (this.locationCity == city.name) {
                console.log(index, idx, "idx");
                setTimeout(() => {
                  this.$refs.areaPicker.setIndexes([index, idx]);
                }, 0);
              }
            });
          });
          this.hospitalList = res;
          this.getCityOrgan();
          this.getData(res[0].id);
        })
        .catch(() => {});
    },
    // 医生详情
    toDetail(item) {
		this.$http.request("cardCount",{id:item.id}).then(()=>{}).catch(()=>{})
      this.$router.push("/doctorDetail?docId=" + item.id);
    },
    // 关注
    followDoctor(item) {
      Toast.loading();
      this.$http
        .request("followDoctor", {
          id: this.$cache.local.getItem("patientId"),
          doctorId: item.id,
        })
        .then((res) => {
          // Notify({ type: "success", message: "关注成功" });
          Toast.success("关注成功");
          this.getCityPageData(this.locationCity);
        })
        .catch((err) => {});
    },
    // 取消关注
    cancelFollowDoctor(item) {
      Dialog.confirm({
        title: "温馨提示",
        message: "您确认取消关注此医生吗？",
        confirmButtonColor: "#1989fa",
      })
        .then(() => {
          Toast.loading();
          this.$http
            .request("cancelFollowDoctor", {
              id: this.$cache.local.getItem("patientId"),
              doctorId: item.id,
            })
            .then((res) => {
              Toast.success("取关成功");
              this.getCityPageData(this.locationCity);
            })
            .catch((err) => {});
        })
        .catch(() => {
          // on cancel
        });
    },
    convertDoctorProfessional(val) {
      if (val == 1) {
        return "主任医师";
      } else if (val == 2) {
        return "副主任医师";
      } else if (val == 3) {
        return "主治医师";
      } else if (val == 4) {
        return "医士";
      } else if (val == 5) {
        return "主任药师";
      } else if (val == 6) {
        return "副主任药师";
      } else if (val == 7) {
        return "主管药师";
      } else if (val == 8) {
        return "药士";
      } else if (val == 9) {
        return "主任护师";
      } else if (val == 10) {
        return "副主任护师";
      } else if (val == 11) {
        return "主管护师";
      } else if (val == 12) {
        return "护士";
      } else {
        return val;
      }
    },
    // 获取选中市id 当前机构
    getCityOrgan() {
      if (this.locationCity) {
        for (let i in this.hospitalList) {
          if (!this.hospitalList[i].children) {
            continue;
          }
          for (let j in this.hospitalList[i].children) {
            if (this.hospitalList[i].children[j].name == this.locationCity) {
              this.$cache.session.setItem(
                "addrId",
                this.hospitalList[i].children[j].id
              );
              this.$cache.session.setItem("addrName", this.locationCity);
              console.log(
                this.$cache.session.getItem("addrId"),
                this.$cache.session.getItem("addrName")
              );
              return;
            }
          }
        }
        this.$cache.session.setItem("addrId", "");
        this.$cache.session.setItem("addrName", "");
        return;
      } else {
        this.$cache.session.setItem("addrId", "");
        this.$cache.session.setItem("addrName", "");
        return;
      }
    },
    // 在线咨询
    venice() {
		this.$http.request("zxCount",{}).then(()=>{}).catch(()=>{})
      this.$router.push("/onConsult");
    },
    //获取咨询提醒列表
    gethzList() {
      this.$http
        .request("getChatRoomStatusRoom", {
          patientId: this.$cache.local.getItem("patientId"),
        })
        .then((res) => {
          this.hzList = res;
        })
        .catch(() => {});
    },
    // 会诊状态更改
    changeHzList(item) {
      if (item.msgContent) {
        try {
          let m = JSON.parse(item.msgContent);
          for (let i in this.hzList) {
            if (this.hzList[i].id == m.id) {
              this.hzList[i].roomStatus = m.roomStatus;
              return;
            }
          }
          this.hzList.push(m);
        } catch {}
      }
    },
    // 咨询提醒点击跳转
    toRoomOrorder(item) {
      if (item.roomStatus == 0) {
        this.$router.push({
          path: "/orderDetail",
          query: {
            orderId: item.assistantOrderId,
            seeDoctorId: item.seeDoctorId,
          },
        });
      } else if (item.roomStatus == 1) {
        this.$router.push({
          path: "/chatRoom",
          query: {
            roomId: item.id,
            seeDoctorId: item.seeDoctorId,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.refresh {
  // background-color: red;
  height: 100px;
}
.home-wrapper {
  position: relative;
  height: 100%;
  background-color: #fff;
  .phone-box {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    overflow: auto;
    .top-bg {
      background: -webkit-linear-gradient(top, #278eff, white);
      height: 260px;
      .top-logo {
        padding: 0 15px;
        padding-top: 15px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          height: 20px;
          object-fit: contain;
        }
        .van-field {
          width: 30%;
          background-color: rgba($color: #fff, $alpha: 0.3);
          border-radius: 80px;
          padding: 5px 10px;
          ::v-deep(.van-field__control) {
            color: #fff;
          }
          ::v-deep(.van-cell__right-icon) {
            color: #fff;
          }
        }
        .hospital-name {
          color: #fff;
          font-size: 14px;
          line-height: 34px;
        }
      }
      .search-box {
        margin: 0 15px;
      }
      .area-type {
        margin: 15px;
        .area1 {
          display: flex;
          justify-content: space-around;
          .area1-item {
            width: calc(100% / 3);
            height: 130px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
              width: 60px;
            }
            .text1 {
              color: #333333;
              font-size: 18px;
              font-weight: bold;
              margin: 2px 0;
              margin-top: 5px;
            }
            .text2 {
              color: #999;
              font-size: 14px;
              line-height: 20px;
              height: 40px;
              text-align: center;
            }
          }
        }
        .area2 {
          display: flex;
          justify-content: space-around;
          .area2-item {
            background-color: rgba($color: #fff, $alpha: 0.5);
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // align-items: center;
            position: relative;
            width: 48%;
            height: 90px;
            padding: 0 10px;
            box-sizing: border-box;
            img {
              width: 60px;
              position: absolute;
              right: 0;
              bottom: 0;
            }
            .text11 {
              font-size: 20px;
              font-weight: bold;
              margin: 2px 0;
              margin-bottom: 5px;
            }
            .col1 {
              color: #2ac2a8;
            }
            .text22 {
              color: #666;
              font-size: 13px;
            }
            .col2 {
              color: #278eff;
            }
          }
        }
      }
    }

    .banner-list {
      padding: 15px;
      box-sizing: border-box;
      // margin-top: 25px;
      img {
        border-radius: 8px;
        width: 100%;
        height: 130px;
        object-fit: cover;
      }
    }

    .depart-type {
      display: flex;
      flex-wrap: wrap;
      .depart-item {
        width: 16%;
        // background-color: red;
        margin: 5px 2%;

        .depart-item-con {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          img {
            width: 30px;
            object-fit: contain;
          }
        }
      }
    }
    .doctor-list {
      // background-color: rgb(127, 180, 255);
      .doctor-item {
        display: flex;
        // justify-content: center;
        align-items: center;
        border-bottom: 1px solid #ccc;
        padding: 15px;
        box-sizing: border-box;
        width: 100%;
        .van-image {
          flex-shrink: 0;
          border-radius: 50%;
        }
        .doctor-rgt {
          margin-left: 15px;
          width: calc(100% - 80px);
          .rgt-one {
            display: flex;
            justify-content: space-between;
            .name {
            }
            .text {
              font-size: 13px;
              margin-left: 10px;
              color: #272d3b;
            }
            .team-box {
              color: #278eff;
              background-color: #e8f3fe;
              font-size: 12px;
              padding: 2px 5px;
              border-radius: 2px;
              margin-left: 5px;
            }
          }

          .doctor-text {
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 13px;
            color: #b8c0c7;
            margin: 10px 0;
          }
          .doctor-tag {
            background-color: #fff5e6;
            color: #f79c42;
            font-size: 12px;
            border-radius: 2px;
            margin-right: 10px;
            padding: 0 5px;
          }
        }
      }
    }

    .icon-title {
      display: flex;
      align-items: center;
      height: 30px;
      margin-top: 25px;
      margin-bottom: 5px;
      font-weight: bold;
      padding: 0 15px;
      box-sizing: border-box;
      font-size: 16px;
      img {
        margin-right: 5px;
        width: 5px;
      }
    }
    .depart.icon-title {
      margin-top: 5px;
    }
    .title-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .more-doc {
        color: #5d6e7f;
        font-size: 13px;
      }
    }
    .empty-text {
      color: #9da7b1;
      font-size: 13px;
      margin-left: 10px;
      padding: 0 15px;
      box-sizing: border-box;
    }
    .empty-text-center {
      color: #9da7b1;
      font-size: 13px;
      margin-left: 10px;
      padding: 0 15px;
      box-sizing: border-box;
      text-align: center;
      margin-top: 20px;
    }
  }
  .bot-msg {
    width: 100%;
    // background-color: #e8f3ff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin: 15px;
    padding: 0 15px;
    box-sizing: border-box;
    // border-radius: 8px;
    height: 50px;
    line-height: 50px;
    font-size: 15px;
    bottom: 0;
    color: #fff;
    span {
      display: flex;
      align-items: center;
    }
  }
  .bot-msg-vol1 {
    background-color: rgba($color: #2ac2a8, $alpha: 0.7);
  }
  .bot-msg-vol2 {
    background-color: rgba($color: #f79c42, $alpha: 0.7);
  }
  .dw-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    // .hzItem{}
  }
}
</style>