<template>
  <!-- <div class="title">个人中心</div> -->
  <div class="my-wrapper">
    <div class="top-box">
      <van-image
        round
        width="1rem"
        height="1rem"
        :src="userInfo.patientHeadImg"
        fit="contain"
      />
      <div class="top-rgt">
        {{ userInfo.patientName }}
      </div>
    </div>
    <div class="pat-box">
      <div class="pat-empty" v-if="patList.length == 0" @click="addPatient">
        <van-icon name="plus" />
        <p>添加就诊人（0/5）</p>
      </div>
      <div class="pat-list" v-else>
        <van-swipe :loop="false" :show-indicators="false" :width="160">
          <van-swipe-item
            style="width: 140px"
            class="pat-list-item pat-list-add"
            @click="addPatient"
          >
            <van-icon name="plus" />
            <p>添加就诊人（{{ patList.length }}/5）</p>
          </van-swipe-item>
          <van-swipe-item
            style="width: 140px"
            v-for="item in patList"
            class="pat-list-item"
            @click="lookPatient(item)"
          >
            <div>
              <span class="name">{{ item.name }}</span>
              <span class="benren" v-if="item.isSelf == 1">本人</span>
            </div>
            <div class="item-sex">
              <span>{{ item.sex == 1 ? "男" : "女" }}</span>
              <img
                v-if="item.sex == 1"
                src="../../assets/img/icon/man.png"
                alt=""
              />
              <img
                v-if="item.sex == 2"
                src="../../assets/img/icon/woman.png"
                alt=""
              />
              <span class="age">{{ item.age }}岁</span>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="con-box">
      <div class="line-order">
        <div>
          <img class="img" src="../../assets/img/icon/ti.png" alt="" />
          <h3>我的订单</h3>
        </div>
        <span class="all-order" @click="toOrderList(0)">全部订单 ></span>
      </div>
      <div class="order-box">
        <div @click="toOrderList(1)">
          <img src="../../assets/img/icon/order1.png" alt="" />
          <span>待支付</span>
        </div>
        <div @click="toOrderList(6)">
          <img src="../../assets/img/icon/order1.png" alt="" />
          <span>进行中</span>
        </div>
        <div @click="toOrderList(5)">
          <img src="../../assets/img/icon/order1.png" alt="" />
          <span>已完成</span>
        </div>
        <div @click="toOrderList(2)">
          <img src="../../assets/img/icon/order1.png" alt="" />
          <span>已支付</span>
        </div>
      </div>
      <!-- <van-grid>
        <van-grid-item icon="photo-o" text="待支付" />
        <van-grid-item icon="photo-o" text="进行中" />
        <van-grid-item icon="photo-o" text="已完成" />
        <van-grid-item icon="photo-o" text="已取消" />
      </van-grid> -->
      <div class="line-title">
        <img class="img" src="../../assets/img/icon/ti.png" alt="" />
        <h3>我的服务</h3>
      </div>

      <van-cell
        title="关注医生"
        icon="description"
        is-link
        :to="{
          path: '/doctorList',
          query: {
            patId: $cache.local.getItem('patientId')
              ? $cache.local.getItem('patientId')
              : -1,
          },
        }"
      />
      <van-cell
        title="收藏文章"
        icon="shield-o"
        is-link
        :to="{
          path: '/mediaList',
          query: {
            patId: $cache.local.getItem('patientId')
              ? $cache.local.getItem('patientId')
              : -1,
          },
        }"
      />
    <!--  <van-cell
        title="修改手机号"
        icon="phone-circle-o"
        is-link
        :to="{ path: '/phoneUpdate', query: { phone: userInfo.patientPhone } }"
      /> -->
	  <van-cell
	    title="账号管理"
	    icon="shield-o"
	    is-link
	    :to="{
	      path: '/account',
	      query: { phone: userInfo.patientPhone },
	    }"
	  />
	  <van-cell
	    title="发票管理"
	    icon="shield-o"
	    is-link
	    :to="{
	      path: '/invoice',
	      query: {},
	    }"
	  />
	  <van-cell title="协议"  is-link  to="/protocolList" icon="description"  />
      <van-cell title="版本号" icon="label-o" value="v1.1.1" />
	  
	  
    </div>
	
  </div>
</template>

<script>
import {
  Cell,
  Image,
  Dialog,
  Icon,
  Swipe,
  SwipeItem,
  Grid,
  GridItem,
  Button,
  Popup
} from "vant";
import protocola from "../protocol/protocola.vue";
import protocolb from "../protocol/protocolb.vue";
import protocold from "../protocol/protocold.vue";
export default {
  name: "myCenter",
  components: {
    [Cell.name]: Cell,
    [Image.name]: Image,
    [Dialog.name]: Dialog,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
	[Popup.name]:Popup,
	[Button.name]:Button,
	protocola,
	protocolb,
	protocold
  },
  data() {
    return {
      userInfo: {
        avatar: "",
        name: "",
      },
      patList: [],
	  isShow:{
		  axy:false,
		  bxy:false,
		  dxy:false,
	  },
    };
  },
  created() {
    this.getUserInfo();
  },
  mounted() {
    this.queryPatList();
  },
  methods: {
    queryPatList() {
      this.$http
        .request("queryPatList", {
          patientId: this.$cache.local.getItem("patientId"),
        })
        .then((res) => {
          console.log(res, "成功");
          this.patList = res;
        })
        .catch(() => {});
    },
    addPatient() {
      if (this.patList.length < 5) {
        this.$router.push("/patientAdd");
      } else {
        Dialog.confirm({
          title: "温馨提示",
          message:
            "您的就诊人已达到最大数量,如需添加新的就诊人，请先删除其他的就诊人",
          theme: "round-button",
          confirmButtonColor: "#1989fa",
          showCancelButton: false,
        })
          .then(() => {
            // on confirm
          })
          .catch(() => {
            // on cancel
          });
      }
    },
    lookPatient(item) {
      this.$router.push("/patientInfo?id=" + item.id);
    },
    toOrderList(val) {
      this.$router.push("/orderList?isOrderStatus=" + val);
    },
    // 获取用户信息
    getUserInfo() {
      this.$http
        .request("getPatientInfo", {
          id: this.$cache.local.getItem("patientId"),
        })
        .then((res) => {
          this.userInfo = res;
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  background-color: #bfdeff;
  text-align: center;
  font-size: 18px;
  padding: 15px 0;
}
.my-wrapper {
  .top-box {
    display: flex;
    align-items: center;
    padding: 15px;
    box-sizing: border-box;
    background: -webkit-linear-gradient(top, #74c6ff, #1e88ff);
    height: 100px;

    .top-rgt {
      font-weight: bold;
      margin-left: 10px;
      color: #fff;
      font-size: 18px;
    }
  }
  .pat-box {
    .pat-empty {
      background-color: #fff;
      border-radius: 8px;
      height: 80px;
      width: 90%;
      margin: 0 auto;
      margin-top: -20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      box-shadow: 0px 2px 6px 0px rgba(50, 84, 153, 0.2);
    }
    .pat-list {
      display: flex;
      // margin: 15px;
      margin-top: -20px;
      height: 80px;

      .pat-list-item {
        width: 140px;
        background-color: #fff;
        border-radius: 8px;
        height: 70px;
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        box-shadow: 0px 2px 6px 0px rgba(50, 84, 153, 0.2);
        .benren {
          background-color: #278eff;
          color: #fff;
          padding: 2px 6px;
          font-size: 13px;
          border-radius: 3px;
          margin-left: 10px;
        }
        .item-sex {
          margin-top: 5px;
          font-size: 15px;
          color: #333;
          display: flex;
          align-items: center;
          .age {
            margin-left: 10px;
          }
          img {
            width: 18px;
          }
        }
      }
      .pat-list-add {
        font-size: 14px;
      }
    }
  }
  .con-box {
    margin: 15px;
    .line-order {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 35px;
      > div {
        display: flex;
        align-items: center;
        .img {
          width: 5px;
          height: 13px;
          margin-right: 5px;
        }
        h3 {
          font-size: 16px;
          color: #333;
        }
      }
      .all-order {
        color: #999;
      }
    }

    .line-title {
      display: flex;
      align-items: center;
      line-height: 35px;
      margin-top: 20px;
      .img {
        width: 5px;
        height: 13px;
        margin-right: 5px;
      }
      h3 {
        font-size: 16px;
        color: #333;
      }
    }
    .order-box {
      display: flex;
      > div {
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 30px;
        }
      }
    }
    .cell-box {
      margin: 15px;
      background-color: #fff;
      border-radius: 8px;
    }
  }
}
.xytknr {
  width: 80vw;
  box-sizing: border-box;
  padding: 20px;
}
</style>