<template>
  <div class="xy-box">
    <h3 class="title">软件许可及服务协议</h3>
    <div class="content">
      <p class="bold">欢迎您使用我们的服务</p>
      <p>
        “我们”是会诊帮医生集团（深圳）有限公司（以下简称“会诊帮”），注册地位于中国深圳。我们是“会诊帮用户端”小程序的运营方。在本协议中，“我们”还包括共同为您提供服务的我们的分支机构和关联公司。
      </p>
      <p>“您”是指“会诊帮用户端”小程序的注册用户。</p>
      <p>
        本协议是您与我们之间关于您使用我们的服务所订立的协议，包括协议正文、《隐私政策》和其他平台业务规则（以下统称“本协议”），请您仔细阅读。
      </p>
      <p>
        您点击“注册”按钮，即视为您同意并接受本协议的全部条款，本协议即构成对您与我们具有约束力的法律文件。您在点击同意/确认本协议前，请务必审慎阅读、充分理解各条款内容，特别是以粗体下划线标识，您应重点阅读。您在使用我们的某一特定服务时，该服务可能存在相关业务规则、说明或公告等（以下统称“平台业务规则”），您在使用该项服务前请详细阅读平台业务规则内容，当您点击确认或者使用前述特定服务时，即视为您已阅读并同意接受相关平台业务规则的约束。
      </p>
      <p>如果您未满18周岁，您只能在父母或监护人的监护下使用我们的服务。</p>
      <p class="bold">1. 我们的服务</p>
      <p>
        我们向您提供互联网平台信息技术服务（以下简称“我们的服务”），您可以通过“会诊帮用户端”小程序或我们发布的其它渠道（以下统称“我们的平台”），登录您已注册的“会诊帮”账户，以使用我们的服务。我们的服务自“会诊帮用户端”小程序运行即开始向您提供。您可以通过使用我们的服务，从第三方享受远程问诊、健康咨询及其他服务。
      </p>
      <p>
        在我们的平台上，诸如医疗咨询、健康咨询等服务均由有相关资质的独立第三方向您提供，您可以通过我们的平台和服务来使用这些第三方提供的服务（以下简称“第三方服务”）。您在使用这些第三方服务时，您在遵守本协议的同时，还需要优先遵守这些第三方服务的相关规则，这些规则会在您首次使用相关服务的时候向您展示，请您留意阅读。第三方服务将由该服务的实际提供方向您承担相应的责任，我们会给予您必要的协助，并在法律要求的必要范围内承担相应的责任。
      </p>
      <p>
        为了使用我们的服务，您需要自行准备上网设备，包括并不限于智能手机或平板电脑等上网终端、调制解调器及其他必备的上网装置，并承担可能存在的上网开支，包括但不限于网络接入费、上网设备租用费、手机流量费等，此类费用均不是由我们收取的。
      </p>
      <p>
        除上述开支外，我们的平台上的某些服务是以收费方式提供的，您可以在您的账户登录状态下进行相关费用的支付，并且在您支付成功后通过该账户使用相应的付费服务。只有相关款项被及时、足额地支付之后，您才能获得相应的服务。对于收费服务，我们会在收费前取得您的同意。
      </p>
      <p>
        我们可能根据实际需要对收费服务的收费标准、方式进行修改和变更。某些免费服务可能变更为收费服务，如果相关款项未被及时、足额地支付，您将无法在收费开始后继续使用该服务。我们会对前述变更进行通知或公告，或在一定期限内，当您使用相应服务前予以提示。服务的价格随时可能发生变更，如果出现降价或促销，除另有说明外，我们不提供价格保护或退款。
      </p>
      <p>
        由于软件类商品或服务含授权/激活信息（包括且不限于激活码、密码等），为特殊商品，在交易确立完成后，不适用无理由退货，如无质量问题不予退换货。如果您需要任何咨询或帮助，您可随时与我们取得联系。
      </p>
      <p class="bold">2. 我们的软件及知识产权许可</p>
      <p>
        我们合法拥有“会诊帮用户端”小程序软件和我们的服务中的一切知识产权的权利或必要的授权，我们的“会诊帮用户端”小程序软件和服务中的内容包括但不限于：文字、软件、音视频文件、相片以及图表等。这些内容受著作权法、专利法、商标法及其它所有相关法律法规的保护。您只能在获得我们的授权时才能使用这些内容，而不能擅自复制、修改、编撰这些内容、或创造与内容有关的衍生产品。
      </p>
      <p>
        我们给予您一项个人的、全世界范围内的、不可转让、可撤销及非排他性的许可，以使用“会诊帮用户端”小程序软件和我们的服务。除另有书面约定外，您可以为非商业目的在相应的智能移动终端设备，包括但不限于平板电脑或智能手机等计算机系统（以下简称终端设备）上安装、使用、显示、运行“会诊帮用户端”小程序软件和我们的服务。
      </p>
      <p>
        我们特别提醒您：我们提供的软件和服务仅仅作为工具。使用“会诊帮用户端”小程序获取、处理相关信息时，您应尊重他人知识产权、个人信息、隐私权等合法民事权益，确保您系依法复制、修改、上传或发布该资料。若未经许可复制、上传并向公众传播资料，可能侵犯他人合法权利，并导致您可能要承担相应法律责任。
      </p>
      <p>
        除非您与我们另行达成书面协议，否则本协议未授权您使用我们相关的任何软件名称、商标、服务标记、徽标、域名或其它有显著品牌特征的标识。
      </p>
      <p class="bold">3. 您的账户的注册、使用及安全</p>

      <p>
        您可以直接通过“会诊帮用户端”小程序注册您的账户，或者通过我们发布的其他渠道注册您的账户。
      </p>
      <p>
        您承诺在注册您的账户时提供真实的信息，并愿意承担由于提供虚假信息而对您造成的不利后果以及对我们或第三方造成的任何损失。在您申请开通某些服务时，我们会对您的真实身份进行核实，您承诺将积极地予以配合，否则您将无法使用这些服务。
      </p>
      <p>
        为便于您使用我们的服务和我们与第三方合作向您提供的服务，在征得您的同意后，我们会将您在“会诊帮用户端”小程序注册的账户与您在我们的相关合作方的平台注册的账户进行关联。对于需要账户登录后才能使用第三方服务，如果您在该第三方的平台注册有账户，您同意我们在您使用相关服务时进行上述账户关联。
      </p>
      <p>
        请您妥善保管您的账户密码，并承担该账户项下所有活动产生的全部责任。我们特别提醒您应采取特定措施保护您的账户的安全，包括但不限于妥善保管账户密码、安装防病毒木马软件、定期更改密码、使用后安全退出等。如您账户和密码被盗用，除非您能证明该事件由于我们的故意或重大过失直接导致，否则您应承担被盗用期间产生的一切责任和后果。
      </p>
      <p>
        您的账户只限于您本人使用，请勿与他人共用您的账户，否则，我们将无法保证您的数据安全。您了解并同意，使用您的账户实施的任何行为都视为您的行为，即使是他人使用您的账户实施的行为，您也将承担连带责任。如果您发现有人未经授权使用了您的账户，请立即联系并通知我们。
      </p>
      <p class="bold">4. 隐私与个人信息</p>
      <p>
        我们尊重用户的个人隐私，并依照法律对您的隐私和个人信息进行保护。请您仔细阅读我们的《隐私政策》，该政策规范我们如何处理您的相关信息。未经授权，我们不会对您上传的信息，尤其是您的隐私信息进行擅自披露。
      </p>
      <p>
        当您使用我们的服务时，我们提醒您根据您的需求调整相关隐私设置，以避免相关风险。
      </p>
      <p>
        您理解并承诺，对您了解、接收或可接触到的其他用户和包括您上传的资料信息中的他人的隐私和未公开信息予以充分尊重，未经许可，不得搜集、复制、存储、传播、散播、滥用他人隐私信息和未公开信息。若您恶意使用他人个人信息及未公开信息，或者以其他方式侵害他人的隐私及未公开信息的（包括但不限于对其他用户造成骚扰），我们将随时限制或者终止您的账户的权限直至删除账户，保留随时单方面终止服务协议并追究您法律责任的权利。
      </p>
      <p class="bold">5. 推送信息</p>
      <p>
        为向您提供更好的服务，您充分理解并同意我们在遵守国家相关法律规定的前提下，通过短信、企业微信、小程序内弹窗、push等形式向您推送信息，这些推送信息包括但不限于我们的服务状态通知、营销活动、订单详情或其他服务内容，以及可能对您有用的关于第三方产品或服务的信息。您在小程序使用期间，均可通过访问会诊帮小程序-“我的”-“设置”-“消息通知”进行查阅并选择开启/关闭相关信息推送。此外，我们将可能会通过短信、电话、企业微信的方式跟您联络，在您同意后征求您对我们产品的意见和建议，我们尽可能的使用匿名化的方式进行。
      </p>
      <p>
        我们特别提醒您，如果您收到的推送信息是关于第三方而非我们的，对于此类信息，您应当自行判断其内容的真实性并为自己的行为负责。除法律明确规定外，您因此类信息内容而遭受的任何损失或损害，我们均不承担任何责任。
      </p>
      <p class="bold">6. 用户产生的内容</p>
      <p>您对您上传到您的账户中的合法内容依法享有著作权或其他权利。</p>
      <p>
        任何经由您的账户发送、上传、张贴、发布的内容，无论是公开发布还是私下传送，您都需要对相关内容承担责任，您保证这些内容的合法性，并且您有权传送或发布这些内容。我们保留依法对内容进行审核、屏蔽、删除或因您提供非法内容而对您终止服务的权利。
      </p>
      <p>
        对于您应用与我们的服务中的内容，您同意授予我们在全球范围内享有免许可费、非独家的、可再许可及永久有效的使用权利，按照您的设置和我们的业务规则在法律允许的范围内使用这些内容。
      </p>
      <p class="bold">7. 信息存储及同步</p>
      <p>
        如果您在终端设备中设置使用我们的平台并登录您的账户，该终端设备中使用我们的平台已经存储的数据信息将会自动添加至您的账户，并同步到我们的服务器中，同步成功后，您可以通过在任意终端设备使用我们的平台登录您的账户来获取您成功同步的数据。
      </p>
      <p>
        您如果使用我们的平台在您的账户登录状态下直接修改终端设备中的数据信息，该修改的信息可能将会按照最后改变的结果在终端设备和您的账户之间同步。我们特别提醒您，在您临时用非本人所有的移动终端设备使用您的账户时，请注意对您的设置进行必要调整，以及在使用结束后退出登录，以免泄漏您的个人信息或隐私。
      </p>

      <p class="bold">8. 用户行为规范</p>
      <p>
        您在使用我们的服务时应遵守法律法规，不得制作、复制、发布、传播敏感信息、违法信息或普通人会视为违反道德的任何信息或材料，包括但不限于下列信息：
      </p>
      <p>（1） 反对宪法所确定的基本原则的；</p>
      <p>（2） 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
      <p>（3） 损害国家荣誉和利益的；</p>
      <p>（4） 煽动民族仇恨、民族歧视，破坏民族团结的；</p>
      <p>（5） 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
      <p>（6） 散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
      <p>（7） 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
      <p>（8） 侮辱或者诽谤他人，侵害他人合法权益的；</p>
      <p>（9） 含有法律、行政法规禁止的其他内容的。</p>

      <p>
        您不得为任何非法目的而使用我们的服务，不得利用我们的服务从事违法行为，包括但不限于：
      </p>
      <p>
        （1） 利用我们的服务从事任何可能对互联网的正常运行造成不利影响的行为；
      </p>
      <p>（2） 盗取或非法访问、滥用其他用户的账户或账户内的信息；</p>
      <p>（3） 恶意虚构事实、隐瞒真相以误导、欺骗他人；</p>
      <p>
        （4）
        发表、传送、传播广告信息及垃圾信息，发起、延续或以任何形式参与任何投机或其它非法的计划，或利用网络等通讯方式骚扰他人；
      </p>

      <p>
        （5）
        发布、传送、传播、储存侵害他人知识产权、商业秘密等合法权益的内容；或传送或散布以其他方式实现传送含有受到知识产权法律保护的图像、相片、软件或其他资料的文件，除非您拥有相应的权利、授权或已得到所有必要的认可；
      </p>

      <p>（6） 其他法律法规禁止的行为。</p>
      <p>
        您不得从事任何妨害我们合法权益的行为，除非法律明确允许或我们书面许可，您在使用我们的服务时不得从事本协议未明示授权的一切行为，包括但不限于：
      </p>
      <p>（1） 删除我们的服务中所有关于著作权的信息；</p>
      <p>
        （2）
        对我们的服务中的信息（包括但不限于使用我们的服务时存储到任何终端设备中的信息及客户端与服务器端的交互信息等）进行商业性复制、或更改、修改、挂接运行或创作任何衍生作品或产品，形式包括但不限于使用插件、外挂或非经授权的第三方工具/服务接入、干扰、影响我们的平台和相关系统；
      </p>

      <p>
        （3）
        限制、禁止或用其它方法干涉、影响他人使用或享有我们的服务（家长控制之类的安全和保护工具除外），包括但不限于发布或传输以下性质的任何信息或软件：包含蠕虫、病毒或其它有害功能，或生成的流量级别可能妨碍其他人使用、发送或检索信息；
      </p>

      <p>（4） 通过任何自动方式访问（或尝试访问）我们的服务；</p>
      <p>
        （5）
        对我们的服务中的相关信息内容等进行商业性使用、出租、出借、复制数据、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
      </p>

      <p>
        （6）
        出于操纵或扭曲普通人认知为目的而进行的任何操作、评级或评论，或者可能影响我们的服务的完整性或准确性的任何行为。
      </p>

      <p>（7） 其他未经我们明示授权的行为。</p>
      <p>
        如果您实施了本条规定的上述行为，您应当独立为此承担相应责任，我们不承担任何责任。由此造成损害的，您应依法予以赔偿。
      </p>
      <p>
        因为您违反本协议规定的行为而对我们造成的损失，包括但不限于因您的行为导致或产生的任何第三方向我们主张的任何索赔、要求或损失，以及我们处理这些事务的必要支出，例如调查费、取证费、律师费等，您同意赔偿我们的上述全部损失，使我们免受损害。
      </p>
      <p class="bold">9. 责任限制</p>
      <p>
        请您从我们提供的官方途径创建您的账户和使用我们的服务，否则，我们无法保证您的账户的安全性，也无法承担任何责任。
      </p>
      <p>
        请您正确使用您的账户，您也可以直接联系我们，以获取必要的帮助，因您设置或操作不当造成的损失将由您自行承担。
      </p>
      <p>
        您完全理解，您的账户涉及互联网服务，可能会遇到各种不可抗力等风险因素，使相关服务发生中断。我们将努力及时修复，但是如果由此造成损失，我们将不予承担。这些意外风险包括但不限于如下原因导致的服务中断和受阻：
      </p>
      <p>（1） 受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；</p>
      <p>（2） 用户或我们的终端设备软件、系统、硬件和通信线路出现故障；</p>
      <p>（3） 意外事件、自然灾害或政府行为；</p>
      <p>（4） 您使用非经我们提供或授权提供的服务；</p>
      <p>（5） 其他不可抗力或我们无法控制的因素。</p>

      <p>
        我们的服务是按照现有技术和条件所能达到的现状提供的。我们会尽最大努力向您提供令人满意的服务，确保服务的连贯性和安全性，但我们不能随时预见和防范法律、技术或其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。您明确理解和同意，我们对无法合理预知和不可控制的产品技术瑕疵、服务的稳定性以及由此产生的任何损失或损害不作任何形式的担保，除法律法规有明确的规定外，我们不承担上述不确定的风险和责任。
      </p>
      <p>
        除有特别说明或法律法规的强制性规定外，我们的服务中所承载的内容（文、图、视频、音频）均为传播有益健康资讯为目的，其内容仅供您谨慎参考，尤其不能作为个别诊断、用药和使用的根据。我们致力于提供正确、完整的健康资讯，但是我们无法保证信息的正确性和完整性，因此我们不对其真实性、科学性、严肃性，以及因信息的不正确或遗漏导致的任何损失或损害承担责任。
      </p>

      <p class="bold">10. 关于未成年人的使用</p>
      <p>
        我们的服务并不直接面向未成年人用户。如果您是未成年人用户，并且一定要使用我们的服务，请征得您的监护人和/或其他具有监护职责的成年人的同意，并在您的监护人和/或其他具有监护职责的成年人的指导、监督下进行使用。此外，您的监护人和/或其他具有监护职责的成年人有责任帮助您正确理解本协议以及其它相关法律文件的内容，从而引导您正确使用我们的服务。
      </p>
      <p class="bold">11. 侵权通知及反向通知 侵权通知：</p>
      <p>
        除了本协议的条款之外，为了保护知识产权，如果您发现有我们的用户通过使用我们的服务传输信息而侵犯了您合法权利或利益，您可以以书面形式按照16条所列通讯方式向我们提交一份正式的侵权通知。侵权通知将包括以下内容：
      </p>
      <p>
        （1）
        您的详细联系信息，包括姓名，身份证或者护照的复印件，地址，电话号码，传真和电子邮件地址；
      </p>

      <p>
        （2）
        在我们的服务中展示的涉嫌侵权内容及发布者的相关信息，以便我们通知管理该内容的用户；
      </p>

      <p>（3） 能够初步证明侵权行为的材料，该等材料应符合以下要求：</p>
      <p>
        a)
        包含您对涉嫌侵权的内容拥有商标权、著作权或其他依法可以行使权利的证明；
      </p>

      <p>b) 指明涉嫌侵权内容侵犯了以上条目a）中列明的具体的权利；</p>
      <p>
        c)
        包括以下声明：（i）您是相关权利的所有人或者被授权行使该权利，您的合法权利被涉嫌侵权网页中的内容所侵犯；（ii）您声明在该侵权通知中的信息是充分的、真实的和准确的，您确认如果本侵权通知中的声明不真实，您将就此虚假的陈述承担法律责任”；
      </p>

      <p>
        （4）
        您在侵权通知上签字，或以其它方式让我们能够确认该侵权通知是按照您的真实意愿发给我们，并且您会承担相应的责任。
      </p>

      <p>反向通知：</p>
      <p>
        如果我们根据所收到的侵权通知移除了与您相关内容或链接，您可以以书面形式按照16条所列通讯方式向我们提交一份正式的反向通知。为了使我们能够尽快处理您的反向通知，请在反向通知中使用以下格式（包括条目编号）：
      </p>
      <p>
        （1）
        您的详细联系信息，包括姓名，身份证或者护照的复印件，地址，电话号码，传真以及电子邮件地址；
      </p>

      <p>（2） 被我们移除的信息、内容或者网页；</p>
      <p>（3） 能够初步证明不侵权的材料，该等材料应符合以下要求：</p>
      <p>
        a)
        能够证明被移除的内容合法性的材料，包括但不限于您拥有对被移除的内容的权利或被授权对被移除的内容行使权利的证明；
      </p>

      <p>
        b)
        包括以下声明：（i）您是相关权利的所有人或者被授权行使该权利，您有权或被授权提供或传播被移除的信息或内容；（ii）您声明在该反向通知中的信息是充分的、真实的和准确的，您确认如果本反向通知中的声明不真实，您将就此虚假的陈述承担法律责任”。
      </p>

      <p>
        （4）
        您在反向通知上签字，或以其它方式让我们能够确认该侵权通知是按照您的真实意愿发给我们，并且您会承担相应的责任。
      </p>

      <p>
        请您将上述侵权通知或反向通知通过我们正式发布的联系方式发送给我们，并冠以“侵权材料的反向通知”的标题。您通过其他渠道发送的内容我们可能不会作为侵权通知或反向通知而有效的进行处理。
      </p>
      <p>
        我们将在收到有效的侵权通知或反向通知后立即依法进行处理。您同意我们有权对通知进行复制并将其提供给相关的第三方以查清相关事实。我们也会在必要的情况下通过电子邮件或其他方式就此事与您取得联系。
      </p>
      <p class="bold">12. 协议的成立及生效</p>
      <p>
        您注册账户以及使用我们的服务等行为即表明您同意接受本协议，即本协议对您发生效力。如您不同意本协议，请立即停止使用我们的服务。
      </p>
      <p class="bold">13. 协议修改</p>
      <p>
        根据国家法律法规变化及网络运营需要，我们有权在必要时对本协议的条款进行修改，修改后的条款一旦被张贴在“会诊帮用户端”小程序上即生效，并代替原来的协议。我们会在协议更新后向您做出提示，您也可随时登录我们的平台查阅最新的协议内容，并有义务不时关注并阅读最新版的协议及客户端公告。如您不同意更新后的协议，请立即停止接受我们的平台提供的服务；如您继续使用我们的平台提供的服务，即视为您同意并接受更新后的协议。如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
      </p>
      <p class="bold">14. 我们的服务的修改、中断、中止与终止</p>
      <p>
        您可以随时终止使用我们的服务，同时注销您的账户。如果您要注销您的账户，您可通过访问会诊帮用户端—>我的—>设置—>账户信息—>注销账户信息。
      </p>
      <p>
        在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。
      </p>
      <p>
        如发生下列任何一种情形，我们有权不经通知而中断或终止向您提供我们的服务：
      </p>
      <p>（1） 您提供的个人信息不准确，不真实或不合法有效；</p>
      <p>（2） 您违反本协议的规定；</p>
      <p>（3） 您未向我们足额付费，由此会导致相应的付费服务的中断或终止；</p>
      <p>（4） 按照法律规定或主管部门的要求；</p>
      <p>（5） 其他紧急且必要的情形。</p>

      <p>
        您的账户被注销后，您的账户下的信息将被彻底删除，且我们不再对您承担任何义务。
      </p>
      <p class="bold">15. 法律适用与管辖</p>
      <p>
        本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用的有效法律。如发生本协议与适用的法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。若您和我们之间发生任何纠纷或争议，我们愿意与您友好协商解决；如果协商不成的，您同意纠纷或争议由我们注册地法院管辖受理。
      </p>
      <p class="bold">16. 其他</p>
      <p>
        本协议的所有条标仅仅是为了醒目及阅读方便，本身并无实际涵义，不能作为本协议涵义解释之依据。
      </p>
      <p>
        我们欢迎您就我们的服务提出反馈，除非另有明确声明，您发送的任何反馈内容将被视为无需保密的内容，您同意我们可以自行决定是否公开、发表，您同意授权我们无偿使用，并可以根据具体情况进行修改、改编、调整设计、变更技术方案，或者根据我们的需求进行其他改动。
      </p>
      <p>
        如果您对我们的服务或本协议有任何意见或建议，可通过以下方式与我们联系：
      </p>
      <p>联系电话：400-863-1599</p>
      <p>邮箱：caofang699@163.com</p>
      <p>我们会尽可能的给予您及时而必要的帮助。</p>
    </div>
  </div>
</template>
	
	<script>
</script>
	
  <style scoped lang="scss">
.xy-box {
  .title {
    text-align: center;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .content {
    line-height: 20px;
    color: #3c4247;
    font-size: 13px;
    text-align: justify;
    p {
      margin-top: 10px;
    }
    .bold {
      font-weight: bold;
      color: #121314;
      font-size: 14px;
    }
  }
}
</style>