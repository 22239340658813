/* 验证函数和el-form正则验证 */
//各种验证
//电话与手机的验证
function isPhone(mobile) {
  var tel = /^0\d{2,3}-?\d{7,8}$/;
  var phone = /^1[3456789]\d{9}$/;
  if (mobile.length == 11) {
    //手机号码
    if (phone.test(mobile)) {
      return true;
    }
  } else if (mobile.length == 13 && mobile.indexOf("-") != -1) {
    //电话号码
    if (tel.test(mobile)) {
      return true;
    }
  } else {
    return false;
  }
}
//判断输入的身份证是否正确
function isIdCard(UUserCard) {
  var regIdCard =
    /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;

  if (!regIdCard.test(UUserCard)) {
    return false;
  } else {
    return true;
  }
}
// 邮箱校验
function isMailbox(val) {
  var reg = new RegExp(
    "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
  ); //正则表达式
  if (!reg.test(val)) {
    return false;
  } else {
    return true;
  }
}
//日期验证 （---
function isDate(dateString) {
  if (dateString.trim() == "") {
    alert("请输入日期");
    return false;
  }
  // var regex = /^(([0-2][0-3])|([0-1][0-9])):[0-5][0-9]:[0-5][0-9]$/;
  var r = dateString.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
  if (r == null) {
    alert(
      "请输入格式正确的日期\n\r日期格式：yyyy-mm-dd\n\r例    如：2008-08-08\n\r"
    );
    return false;
  }
  var d = new Date(r[1], r[3] - 1, r[4]);
  var num =
    d.getFullYear() == r[1] && d.getMonth() + 1 == r[3] && d.getDate() == r[4];
  if (num == 0) {
    alert(
      "请输入格式正确的日期\n\r日期格式：yyyy-mm-dd\n\r例    如：2008-08-08\n\r"
    );
    return false;
  }
  return r[1] + "-" + r[3] + "-" + r[4];
}

let rules = {
  phone: {
    pattern: /^1[3456789]\d{9}$/,
    message: "请填写正确的号码",
  },
  idCard: {
    pattern:
      /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/,
    message: "请输入正确的身份证号",
  },
  mailbox: {
    pattern: /^\w+@[a-z0-9]+\.[a-z]+$/i,
    message: "请输入正确的邮箱号",
  },
  a: { pattern: /^[A-Za-z0-9]+$/, message: "只能输入大小写字母和数字" },
  b: { pattern: /^\d+$/, message: "请输入大于或等于0的整数" },
  c: { pattern: /^[1-9]\d*$/, message: "请输入大于0的整数" },
};
//手机电话   /^1[3456789]d{9}$/
// 身份证 /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/
//文件名只能包含中文、英文、数字包括下划线  /^[\u4E00-\u9FA5A-Za-z0-9_.]+$/
// 匹配正整数不包括0 /^[1-9]\d*$/
// 匹配正整数包括0 /^\d+$/
//匹配0-20位的中文  /^[\u4e00-\u9fa5]{0,20}$/
// 验证手机、时间、身份证、邮箱、-日期格式、
export default {
  isPhone,
  isIdCard,
  isMailbox,
  form_rules: rules,
};
