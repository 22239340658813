import { createStore } from "vuex";

export default createStore({
  state: {
    // 窗口视图状态
    visibilityState: true,
    // 系统时间状态
    serverTimeState: 1,
    // 日历最小日期
    minDate: new Date("2000/01/01"),
    // 全局ws信息集合
    wsMsgList: [],
    // ws 发过来的最新消息
    wsNewMsg: "",
    wsMsgNum: 0,
    // 职称
    jobTitleList: [
      { lable: "主任医师", id: "1" },
      { lable: "副主任医师", id: "2" },
      { lable: "主治医师", id: "3" },
      { lable: "医士", id: "4" },
      { lable: "主任药师", id: "5" },
      { lable: "副主任药师", id: "6" },
      { lable: "主管药师", id: "7" },
      { lable: "药士", id: "8" },
      { lable: "主任护师", id: "9" },
      { lable: "副主任护师", id: "10" },
      { lable: "主管护师", id: "11" },
      { lable: "护士", id: "12" },
    ],
    // 会诊帮appid --通用appid
    appid: "wx26462db1bd091bdd",
    // appid: "wx78a9fa0ab376866b",
    // 新增会诊
    addHzRoom: {},
    // 新增咨询
    addNewZx: {},
    //聊天室状态更改
    changeRoomTypeObj: {},
    // 设备连接最新消息
    mediaState: {},
    // 预约时间修改
    changeYuyueObj: {},
    // 会诊结束
    roomEndObj: {},
  },
  mutations: {
    // 添加聊天
    pushWsMsgList(state, item) {
      // ws 传过来的
      if (typeof item == "object") {
        // 聊天室消息
        if (item.msgModes.indexOf(1) != -1) {
          try {
            let l = {
              ...item,
              assistantConsultId: item.consultId,
              content: item.msgContent,
              // "sendType": 1, //接收到的都是用户传过来的
            };
            if (l.msgType == 6) {
              l.fileUrl = l.content.split(",")[0];
              l.fileName = l.content.split(",")[1];
              //   获取最后一个"."之后的内容
              let index = l.fileUrl.lastIndexOf(".");
              l.fileTypeVal = l.fileUrl.substring(index + 1, l.fileUrl.length);
            }
            let z_id = "r_" + l.roomId;
            if (z_id) {
              l.id = l.msgDataId;
              if (l.msgType == 10) {
                state.wsMsgNum++;
                state.wsNewMsg = l;
              } else if (state.wsMsgList[z_id]) {
                if (state.wsMsgList[z_id].length == 0) {
                  state.wsMsgList[z_id].push(l);
                  state.wsMsgNum++;
                  state.wsNewMsg = l;
                } else if (
                  state.wsMsgList[z_id][0] &&
                  state.wsMsgList[z_id][0].id > l.id
                ) {
                  // 该数据不需要使用；
                  state.wsMsgNum++;
                  state.wsNewMsg = l;
                } else {
                  let roomList = state.wsMsgList[z_id];
                  let roomListi = roomList.length;
                  let xx = [];
                  for (let i = 0; i < roomList.length; i++) {
                    if (roomList[i].id == l.id) {
                      xx.push([l, "重复"]);
                      break;
                    } else if (
                      roomList[i].id < l.id &&
                      roomList[i + 1] &&
                      roomList[i + 1].id > l.id
                    ) {
                      state.wsMsgList[z_id].splice(i + 1, 0, l);
                      state.wsMsgNum++;
                      state.wsNewMsg = l;
                      xx.push([l, "添加" + roomList[i].id + "之后"]);
                      break;
                    } else if (roomList[i].id < l.id && !roomList[i + 1]) {
                      state.wsMsgList[z_id].push(l);
                      state.wsMsgNum++;
                      state.wsNewMsg = l;
                      xx.push([l, "添加" + roomList[i].id + "之后---最后"]);
                      break;
                    }
                  }
                  let ids = [];
                  for (let i in roomList) {
                    ids.push(roomList[i].id);
                  }
                  console.error(ids, roomListi, roomList.length, xx);
                }
              } else {
                state.wsNewMsg = l;
                state.wsMsgNum++;
                state.wsMsgList[z_id] = [l];
              }
            }
          } catch (e) {
            console.error("++++++++++++++++", e);
          }
        }
        // 强提醒
        if (item.msgModes.indexOf(3) != -1) {
          console.log("强提醒");
        }
        // 6 新增咨询--立即咨询
        if (item.msgModes.indexOf(6) != -1) {
          state.addNewZx = item;
        }
        // 7新增会诊房间--会诊房间
        if (item.msgModes.indexOf(7) != -1) {
          // 聊天室添加会诊房间
          state.addHzRoom = item;
        }
        // 8会诊即将开始/会诊进行中/会诊结束 --会诊相关状态
        if (item.msgModes.indexOf(8) != -1) {
          // 首页及聊天室页面判断会诊状态
          state.changeRoomTypeObj = item;
        }
        // 9 会诊结束
        if (item.msgModes.indexOf(9) != -1) {
          state.roomEndObj = item;
        }
        // 10 视频-语音通话 状态
        if (item.msgModes.indexOf(10) != -1) {
          state.mediaState = item;
        }
        // 11 修改预约时间
        if (item.msgModes.indexOf(11) != -1) {
          state.changeYuyueObj = item;
        }
        // 12 订单状态修改
        if (item.msgModes.indexOf(12) != -1) {
          state.changeDingdanObj = item;
        }
      }
      console.log(state.wsMsgList);
    },
    // 重置指定位置的聊天记录
    resetWsMsgList(state, item) {
      // 进入聊天室页面获取的列表记录
      // 浅拷贝 消息列表中的
      let z_id = "r_" + item.roomId;
      if (state.wsMsgList[z_id] != item.list) {
        state.wsMsgList[z_id] = item.list;
      }
    },
    //清空聊天记录
    emptyWsMsgList(state) {},

    //
    /* 
				  // 不存在这种清空--添加缓存时使用 
		        // 获取的历史历史记录--在头部添加
		        // {id:'聊天室id',list: 获取的历史记录=>[{},{}]}
		        if (state.wsMsgList["z_" + item.id]) {
		          state.wsMsgList["z_" + item.id] = item.list.concat(
		            state.wsMsgList["z_" + item.id]
		          );
		        } else {
		          state.wsMsgList["z_" + item.id] = item.list;
		        }
		       */
    //窗体视图状态改变
    changeVisibilityState(state, t) {
      state.visibilityState = t == "visible" ? true : false;
    },
    // 系统时间获取状态
    changeServerTimeState(state, t) {
      state.serverTimeState = t;
    },
  },
  actions: {},
  modules: {},
});
