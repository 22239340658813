<template>
  <div class="home">
    <div v-if="activeList.indexOf(0)!=-1" v-show="active==0" class="top">
      <home-page :isSelect="active==0"  ></home-page>
    </div>
    <div v-if="activeList.indexOf(1)!=-1" v-show="active==1" class="top">
      <media-list></media-list>
    </div>
    <div v-if="activeList.indexOf(2)!=-1" v-show="active == 2" class="top">
      <message-list  :isSelect="active==2" @getMsgUnreadNum="getMsgUnreadNum" :msgUnreadNum="msgUnreadNum" :hzNoread="hzNoread" :zxNoread="zxNoread" style="height: 100%" ></message-list>
    </div>
    <div v-if="active == 3" class="top">
      <my-center></my-center>
    </div>
    <van-tabbar v-model="active"  @change="activeChange" >
      <van-tabbar-item icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item icon="label-o">健康科普</van-tabbar-item>
      <van-tabbar-item icon="comment-o" :badge="msgUnreadNum||''" >消息</van-tabbar-item>
      <van-tabbar-item icon="friends-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
import homePage from "../components/homePage";
import myCenter from "../components/myCenter";
import mediaList from "../components/mediaList";
import messageList from "../components/messageList";
// import indexOf from "core-js/library/fn/array/index-of";
export default {
  name: "Home",
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    homePage,
    mediaList,
    messageList,
    myCenter,
  },
  data() {
    return {
      active: 0,
	  activeList:[], //首页和健康科普应该是缓存
	  msgUnreadNum:0,
	  hzNoread:0,
	  zxNoread:0,
	  // logList:[],
    };
  },
  created() {
  	if(this.$route.query.active){
		this.active=Number(this.$route.query.active)
	}else{
		this.active=0;
	}
	this.getMsgUnreadNum()
	// 浏览数添加
	this.sendBrowseAdd()
  },
  watch:{
	  "$route":{
		 handler :function(i){
	  	console.log("首页路由监听",i,this.active)
	  	if(!i.query.active){
	  		this.active=0;
	  	}else{
	  		this.active=Number(i.query.active)
	  	}
		if(this.activeList.indexOf(this.active)==-1){
			this.activeList.push(this.active)
		}
		console.log(this.active)
	  },
	  	immediate:true
	  },
	  "$store.state.wsNewMsg"(item){
		 //  this.logList.push({
			// date:this.$comm.getDate(new Date()),
			// title:"ws新消息",
			// msg:item,
		 //  })
		  this.getMsgUnreadNum()
	  }
  },
  methods:{
	activeChange(i){
		console.log(i)
		this.$router.push({
			query:{
				active:this.active
			}
		})
		if(i==2){
			this.getMsgUnreadNum()
		}
	},
	// 获取总未读数量、咨询消息总未读数量、会诊消息总未读数量、总最后一条聊天消息id
	getMsgUnreadNum(){
		this.$http.request("getMsgNoReadNum",{
			patientId:this.$cache.local.getItem("patientId")
		})
		.then((res)=>{
			// this.logList.push({
			// 			date:this.$comm.getDate(new Date()),
			// 			title:"获取消息数量",
			// 			msg:res,
			// })
			// console.log("------------2",new Date().getTime(),res)
			this.hzNoread=res.hzNoread||0;
			this.msgUnreadNum=res.totalNoRead||0;
			this.zxNoread=res.zxNoread||0;
		})
		.catch((e)=>{
			console.log(e,"++++++++++++++")
		})
		
		// this.msgUnreadNum=10;
	},
	// 新增浏览数
	sendBrowseAdd(){
		this.$http.request("homeCount",{}).then(()=>{}).catch(()=>{})
	},
  },
};
</script>
<style scoped lang="scss">
.top {
  height: calc(100vh - 50px);
  background-color: #fafafc;
  overflow: auto;
}
</style>
