import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    // childrens:[],
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/downFile",
    name: "downFile",
    component: () => import("../views/downFile.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/profile/index.vue"),
  },
  {
    path: "/fileEdit",
    name: "fileEdit",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/profile/fileEdit.vue"),
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    component: () =>
      import(
        /* webpackChunkName: "forgetPassword" */ "../views/forgetPassword.vue"
      ),
  },
  {
    path: "/huiZenInfo",
    name: "huiZenInfo",
    component: () =>
      import(/* webpackChunkName: "huiZenInfo" */ "../views/huiZenInfo.vue"),
  },
  // 聊天室
  {
    path: "/chatRoom",
    name: "chatRoom",
    component: () =>
      import(/* webpackChunkName: "chatRoom" */ "../views/chatRoom.vue"),
  },
  {
    path: "/onConsult",
    name: "onConsult",
    component: () => import("../views/onConsult.vue"),
  },
  // 聊天室-医生助理聊天
  {
    path: "/yizhuChatRoom",
    name: "yizhuChatRoom",
    component: () => import("../views/yizhuChatRoom.vue"),
  },

  {
    path: "/cs",
    name: "cs",
    component: () => import("../views/cs.vue"),
  },
  {
    path: "/updatePwd",
    name: "updatePwd",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/profile/updatePwd.vue"),
  },
  // 科普
  {
    path: "/mediaList",
    name: "mediaList",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../components/mediaList/index.vue"
      ),
  },
  {
    path: "/mediaDetail",
    name: "mediaDetail",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/mediaDetail.vue"),
  },
  // 订单
  {
    path: "/orderList",
    name: "orderList",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/orderList.vue"),
  },
  {
    path: "/orderDetail",
    name: "orderDetail",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/orderDetail.vue"),
  },
  // 就诊人
  {
    path: "/patientAdd",
    name: "patientAdd",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/patientAdd.vue"),
  },
  {
    path: "/patientInfo",
    name: "patientInfo",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/patientInfo.vue"),
  },
  // 关注专家
  {
    path: "/doctorList",
    name: "doctorList",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/doctorList.vue"),
  },
  {
    path: "/doctorDetail",
    name: "doctorDetail",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/doctorDetail.vue"),
  },
  // 找名医-区域
  {
    path: "/doctorPageArea",
    name: "doctorPageArea",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/doctorPageArea.vue"),
  },
  // 找医院
  {
    path: "/hospitalList",
    name: "hospitalList",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/hospitalList.vue"),
  },
  // 找名医-医院
  {
    path: "/doctorPageHospital",
    name: "doctorPageHospital",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/doctorPageHospital.vue"),
  },
  // 全部科室
  {
    path: "/departmentAll",
    name: "departmentAll",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/departmentAll.vue"),
  },
  // 修改手机号
  {
    path: "/phoneUpdate",
    name: "phoneUpdate",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/phoneUpdate.vue"),
  },
  // 协议列表
  {
    path: "/protocolList",
    name: "protocolList",
    component: () =>
      import(
        /* webpackChunkName: "protocolList" */ "../views/protocolList.vue"
      ),
  },
  // 开票菜单页
  {
    path: "/invoice",
    name: "invoice",
    component: () => import("../views/invoice"),
  },
  // 发票抬头列表
  {
    path: "/invoiceTitle",
    name: "invoiceTitle",
    component: () => import("../views/invoice/invoiceTitle.vue"),
  },
  // 发票抬头详情、新增、修改
  {
    path: "/invoiceTitleChange",
    name: "invoiceTitleChange",
    component: () => import("../views/invoice/invoiceTitleChange.vue"),
  },
  // 发票-订单列表
  {
    path: "/applyInvoiceList",
    name: "applyInvoiceList",
    component: () => import("../views/invoice/applyInvoiceList.vue"),
  },
  //开票历史
  {
    path: "/invoiceHistory",
    name: "invoiceHistory",
    component: () => import("../views/invoice/invoiceHistory.vue"),
  },
  // 账号管理
  {
    path: "/account",
    name: "account",
    component: () => import("../views/account"),
  },
  // 账号注销
  {
    path: "/writeOff",
    name: "writeOff",
    component: () => import("../views/account/writeOff.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
