import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import http from "./http/index.js";
import mystorage from "./utils/storage.js";
import comm from "./utils/comm.js";

import "vant/lib/index.css";

const app = createApp(App);
console.log(app.config);
app.config.globalProperties.$http = http;
app.config.globalProperties.$cache = mystorage;
app.config.globalProperties.$comm = comm;
app.config.productiOnTip = false;
// if (process.env.NODE_ENV == "development") {
//   mystorage.local.setItem(
//     "token",
//     "eyJhbGciOiJIUzI1NiJ9.eyJwYXRpZW50SW5mbyI6eyJpZCI6MiwiY3JlYXRlVGltZSI6IjIwMjMtMDItMjIgMTY6MzE6MjYiLCJ1cGRhdGVUaW1lIjpudWxsLCJpc0RlbGV0ZWQiOjAsInNlYXJjaElucHV0IjpudWxsLCJwYXRpZW50UGhvbmUiOm51bGwsInBhdGllbnROYW1lIjoi5YiY5rGd5L2zIiwicGF0aWVudEFnZSI6bnVsbCwicGF0aWVudFNleCI6bnVsbCwicGF0aWVudEhlYWRJbWciOiJodHRwczovL3RoaXJkd3gucWxvZ28uY24vbW1vcGVuL3ZpXzMyL292azVOaWNrc2U2MWliNUlaZWNSR25rNURLVGljUnBuaHpRc2lhR2FzeWFtaFZ1d3JMRDNiVGQ5bVhpYjhPSnFOb1JpY0x0TGUxdDFPa1JSVG5pYkhwVkxIdm5jQS8xMzIiLCJvcmdhbml6YXRpb25JZCI6MCwib3BlbklkIjoib1RUR0F0OUttY3VvNm1qOE12cG9fWkJwaFhGQSIsInBob25lQ29kZSI6bnVsbCwid3hBcHBpZCI6Ind4MjY0NjJkYjFiZDA5MWJkZCIsInBhdGllbnRBY2NvdW50SWQiOjJ9LCJleHAiOjE2ODYwMzkyNzUsImlhdCI6MTY4NTk1Mjg3NSwianRpIjoiNzgwNjhlN2ItZTljYy00MTVlLWIyYjctMWZhYjAwNzFhOGMzIn0.7oiBSHa8GzfdsHyoYdlHSrWgDKHjaDN7A4xm1Zaq_KE"
//   );
//   mystorage.local.setItem("companyId", 0);
//   mystorage.local.setItem("patientId", 2);
// }

import myWs from "./utils/ws.js";
app.config.globalProperties.$myws = myWs;
import myVerify from "./utils/verify.js";
app.config.globalProperties.$myVerify = myVerify;

// ---服务时间
app.config.globalProperties.$fwDate = new Date().getTime();
app.config.globalProperties.$getFwDate = function () {
  store.commit("changeServerTimeState", 0);
  http
    .request("getServerTimes", {})
    .then((res) => {
      console.log("系统时间", res);
      if (window.fwDateDs) {
        window.clearInterval(window.fwDateDs);
      }
      app.config.globalProperties.$fwDate = res;
      store.commit("changeServerTimeState", 1);
      window.fwDateDs = window.setInterval(() => {
        app.config.globalProperties.$fwDate += 1000;
      }, 1000);
    })
    .catch(() => {});
};
app.config.globalProperties.$getFwDate();

app.use(store).use(router).mount("#app");
