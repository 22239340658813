import store from "../store/index.js";
import { Toast, Dialog } from "vant";
import storage from "./storage.js";
import { MY_HOSTNAME } from "../http/httpData.js";
// import "vant/es/toast/style";
// import 'vant/es/dialog/style';
let myWs = null;
// 心跳
let xtds = null;
// 心跳周期
const xtTime = 3000;
// 心跳指令
let xtText = null; //`{"msgType":"CLIENT HEART"}`;
// 根据心跳判断是否已断开连接
let xtBreakType = true;
let sendList = []; //待发送数据
// 是否主动断开ws链接
let isInitiativeSwitch = false;
// 重连次数
let reconnectNum = 0;
// 重连最大次数 --改为无限制自动重连 此参数 废弃
let reconnectMaxNum = 20;
// 初始化 //http://192.168.0.16:9002/hzb/hzbIM //ws://192.168.0.11:3000/ws/aaa?id=1
// 请提示弹框
let wstoast = null;
function initMyWs(
  patientId,
  wsUrl = "wss://" + MY_HOSTNAME + "/wx_hzb_server/hzb/hzbIM"
) {
  if (process.env.NODE_ENV == "development") {
    // 开发环境
    wsUrl = "wss://wxcheshi.xuekejianshe.com/wx_hzb_server/hzb/hzbIM";
    // wsUrl = "ws://192.168.0.16:9002/hzb/hzbIM";
  }
  close();
  if (!patientId) {
    return;
  }
  isInitiativeSwitch = false;
  // return;
  myWs = new WebSocket(wsUrl);
  sendList = [];
  console.log("ws创建前");
  myWs.onopen = (e) => {
    console.log("ws创建成功", e);
    if (wstoast) {
      wstoast.clear();
      wstoast = null;
      Toast({
        message: "实时通讯创建成功",
        position: "top",
      });
    }
    reconnectNum = 0;
    // 患者连接
    send(`{"msgType":"ADD PATIENT","data":{"patientId":${patientId}}}`);
    //  {"msgType":"MSG SUCCESS","data":"通话信息id"}
    for (let i in sendList) {
      send(sendList[i]);
    }
    sendList = [];
    //  //医理
    if (xtds) {
      console.log("清除定时");
      window.clearInterval(xtds);
    }
    xtds = window.setInterval(() => {
      console.error("发送心跳");
      xtText = JSON.stringify({
        msgType: "CLIENT HEART",
        timestamp: new Date().getTime() + "",
      });
      send(xtText);
      xtBreakType = false;
      window.setTimeout(() => {
        if (myWs) {
          if (!xtBreakType) {
            console.log("心跳没有回执，连接断开");
            // 提示重连
            // alert("WebSocket连接不稳定请刷新重连");
            // Toast("连接不稳定，请检查网络或尝试刷新页面");
            inactiveClose(patientId);
          } else {
            console.log("连接正常");
          }
        }
      }, 2 * 1000);
    }, xtTime);
  };
  myWs.onclose = (e) => {
    console.log("ws断开", e);

    if (xtds) {
      window.clearInterval(xtds);
    }
    myWs = null;
    if (!isInitiativeSwitch) {
      reconnectNum++;

      console.log("非主动断开，自动重连");
      // 获取缓存病人id
      window.setTimeout(() => {
        let pid = storage.local.getItem("patientId");
        if (!isInitiativeSwitch && pid) {
          if (wstoast) {
            wstoast.clear();
            wstoast = null;
          }
          wstoast = Toast({
            message: "实时通讯连接断开，正在为您重连",
            duration: 0,
            position: "top",
          });
          initMyWs(pid);
        }
      }, 1000);

      // }
    }
  };
  myWs.onmessage = (e) => {
    // console.log("接收消息", e.data);
    let l = "";
    try {
      l = JSON.parse(e.data);
      if (l.msgType == "CLIENT HEART") {
        xtBreakType = true;
        return;
      }
      if (
        l.msgModes &&
        (l.msgModes.indexOf(1) != -1 ||
          l.msgModes.indexOf(6) != -1 ||
          l.msgModes.indexOf(7) != -1 ||
          l.msgModes.indexOf(9) != -1 ||
          l.msgModes.indexOf(13) != -1)
      ) {
        let msgConfirmText = JSON.stringify({
          msgType: "MSG CONFIRM",
          data: l.msgId,
          receiveId: l.receiveId,
        });
        send(msgConfirmText);
      }
    } catch (e) {
      console.error("接收的ws信息未对应", e);
      l = e.data;
    }
    store.commit("pushWsMsgList", l);
  };
  myWs.onerror = (e) => {
    console.log("ws错误", e);
  };
}
// 关闭myws
function close() {
  if (xtds) {
    window.clearInterval(xtds);
  }
  if (myWs) {
    console.error("主动断开");
    isInitiativeSwitch = true;
    if (wstoast) {
      wstoast.clear();
      wstoast = null;
    }
    myWs.close();
    myWs = null;
  }
}
// 关闭 myws --非主动断开
function inactiveClose(accountId) {
  if (xtds) {
    window.clearInterval(xtds);
  }
  if (myWs) {
    console.log("非主动断开ws");
    myWs.close();
    myWs.onclose = null;
    myWs = null;
    window.setTimeout(() => {
      if (!isInitiativeSwitch) {
        initMyWs(accountId);
      }
    }, 1000);
  }
}
// 发送消息
function send(msg) {
  if (myWs) {
    if (myWs.readyState == 0) {
      sendList.push(msg);
      return;
    }
    myWs.send(msg);
  }
}

export default {
  initMyWs,
  close,
  send,
};
