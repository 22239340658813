<template>
  <div class="xy-box">
    <h3 class="title">隐 私 政 策</h3>
    <div class="content">
      <p class="bold">关于个人信息保护的隐私政策</p>
      <p>
        会诊帮医生集团（深圳）有限公司（以下简称“会诊帮”）的业务是建立在与客户彼此信任的基础之上的，为了向您提供更优质的服务，以及您能够更便捷地使用我们的产品，会诊帮通过会诊帮小程序平台收集您的部分必要信息。为了使您提供的所有信息都能得到机密保障，会诊帮采用下述关于信息保护政策：
      </p>
      <p>
        1.本平台收集的信息范围仅限于会诊帮认为对了解您的医疗健康需求和开展业务所必需的相关资料，以便会诊帮为您提供全方位的医疗健康服务；
      </p>
      <p>2.会诊帮尽力确保对您的信息记录是准确和及时的；</p>
      <p>
        3.会诊帮平台设有严格的安全系统，以防止未经授权的任何人，包括会诊帮职员获取、泄露您的信息；
      </p>
      <p>
        4.任何第三方，在无论是否得到会诊帮许可获取您的个人信息时，都被要求严格遵守保密责任。
      </p>
      <p>
        保护您的隐私对我们至关重要，我们致力于维持您对我们的信任并恪守原则，全力保护您的个人信息安全可靠。鉴此，会诊帮制定了本隐私政策，旨在向您说明，会诊帮收集使用您的个人信息的范围和规则。请您在使用会诊帮所提供的产品或服务前，务必仔细阅读本隐私政策，尤其是加黑加粗部分。一旦您开始使用会诊帮提供的产品或服务，即表示您已充分理解并同意本隐私声明。
      </p>
      <p>本政策将帮助您了解以下内容:</p>
      <p class="bold">一、适用范围</p>
      <p class="bold">二、我们如何收集和使用您的个人信息</p>
      <p class="bold">三、我们如何共享、转让、公开披露您的个人信息</p>
      <p class="bold">四、个人信息的存储</p>
      <p class="bold">五、我们如何保护您的个人信息</p>
      <p class="bold">六、我们如何使用Cookie（网络跟踪器）和同类技术</p>
      <p class="bold">七、未成年人隐私权保护</p>
      <p class="bold">八、您的个人权利</p>
      <p class="bold">九、隐私政策的更新</p>
      <p class="bold">十、如何联系我们</p>
      <p class="bold">一、适用范围</p>
      <p>
        （一）本隐私政策适用于您通过会诊帮旗下网站、移动应用程序（APP）、微信公众号、会诊帮H5、小程序（以上合称“会诊帮平台”）以及通过第三方网络平台申请会诊帮产品或服务，如远程咨询服务、健康档案、健康科普等。
      </p>
      <p>
        （二）您也可能通过其他网站链接访问会诊帮平台，我们建议您查看该等网站隐私政策，了解他们如何处理您的个人信息，该等网站对您的个人信息的收集、使用、存储、共享、转让、公开披露等行为独立承担责任。
      </p>
      <p>本隐私政策不适用于其他第三方向您提供非会诊帮产品或服务。</p>
      <p class="bold">二、我们如何收集和使用您的个人信息</p>
      <p>(一）收集您个人信息的方式</p>
      <p>
        1.您主动提供的信息：您在产品或服务页面中主动填写的信息、申请产品或服务时、参与网络社区讨论时、投诉与咨询时提供的信息，授权微信公众号、小程序等会诊帮平台收集的个人相关信息，就会诊帮的服务与我们联系时所提供的信息，以及您在使用我们服务过程中产生的您的交易和活动信息。
      </p>
      <p>
        2.我们主动采集的信息：在您使用会诊帮平台及相关服务过程中，我们会收集您的计算机、手机及其他访问设备发送给我们的信息。
      </p>
      <p>
        3.我们从其他来源获得的信息：在取得您同意的前提下，我们还可能从会诊帮合作伙伴、体检机构及经您授权而合法留存您的相关信息的第三方机构获取您的相关信息。
      </p>
      <p>（二）我们如何收集、使用您的个人信息</p>
      <p>会诊帮仅会出于本隐私政策所述的以下目的，收集和使用您的个人信息：</p>
      <p>1、帮助您成为会诊帮平台的注册用户</p>
      <p>
        为成为会诊帮平台的注册用户，您需要提供手机号码进行账户注册并创建密码；您可授权第三方（包括微信、QQ、支付宝）注册的账户登录会诊帮平台，我们会获取您的昵称、头像。如果您仅需使用浏览、搜索等基本服务，您不需要成为会诊帮的注册用户。
      </p>
      <p>2、向您提供医疗健康产品或服务需要</p>
      <p>
        2.1按照法律法规和行业规章等要求，当您向会诊帮申请问医就诊、处方开药、多学科会诊等医疗业务时，我们会收集您的：姓名、性别、国籍、职业、住所地或者工作单位地址、联系方式、身份证件或者身份证明文件的种类、号码和有效期限等信息。
      </p>
      <p>
        2.2会诊帮平台可能会提供健康管理服务，相关增值服务需收集相应信息，如健康状况信息、历史就医信息等。
      </p>
      <p>3、依据法律法规及监管规定履行法定义务</p>
      <p>
        如您知晓，会诊帮为依法成立的互联网医疗公司，需遵循互联网及医疗监管等要求，如客户身份识别和反洗钱义务、实名制管理义务等。这些法定义务要求需收集您的个人身份信息、服务轨迹等（如需）。
      </p>
      <p>
        您知晓，根据您所需医疗服务的不同，以及监管机构的要求，我们所收集的信息类型以产品或服务申请页面显示为准。
      </p>
      <p>4、用户画像和个性化展示</p>
      <p>
        为改善会诊帮的产品或服务、向您提供专业的信息展示及增值服务，会诊帮可能会根据您的基本信息、在会诊帮使用过的服务产品信息、浏览及搜索记录、设备信息、位置信息，根据您的浏览、搜索偏好、行为习惯、位置信息等,为您作特征分析及用户画像，并向您展示或推荐相关程度更高的信息、产品、广告等，并同时提供不针对您个人特征的选项。
      </p>
      <p>
        如果您不想接收会诊帮给您推荐的信息，您可随时通过相应产品退订功能取消。
      </p>
      <p>5、提供客户服务及进行投诉</p>
      <p>
        当您与会诊帮联系时，会诊帮可能会保存您的通信/通话记录和内容、您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。
      </p>
      <p class="bold">三、我们如何共享、转让、公开披露您的个人信息</p>
      <p>（一）共享</p>
      <p>
        在法定情形下的共享：会诊帮可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
      </p>
      <p>
        您授权会诊帮，将您提供给会诊帮的信息、享受会诊帮服务产生的信息、以及会诊帮根据本条款约定查询、收集的信息，用于会诊帮（是指会诊帮医生集团（深圳）有限公司）及其因服务必要委托的合作伙伴为您提供服务、推荐产品、开展市场调查与信息数据分析。
      </p>
      <p>
        3、对会诊帮与之共享个人信息的公司、组织和个人，会诊帮会采取措施，要求他们按照法律法规、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
      </p>
      <p>转让</p>
      <p>
        会诊帮不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
      </p>
      <p>
        在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
      </p>
      <p>
        在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
      </p>
      <p>        公开披露</p>
      <p>
        除在公布中奖活动名单时会脱敏展示中奖者手机号或账户登录名外，原则上，会诊帮不会公开披露您的个人信息。
      </p>
      <p class="bold">四、个人信息的存储</p>
      <p>
        在中华人民共和国境内收集和产生的个人信息，会诊帮将存储在中华人民共和国境内（不含港、澳、台地区）。如会诊帮需向境外传输您的个人信息，则会遵循法律法规的要求，如安全影响评估等。
      </p>
      <p>
        会诊帮将在实现本隐私政策中所述目的所必需的期间保留您的个人信息，除非法律要求或允许在更长的期间内保留您的个人信息。当您的个人信息超出我们的保存期限后，我们会进行匿名化处理。
      </p>
      <p class="bold">五、我们如何保护您的个人信息</p>
      <p>
        会诊帮非常重视您的个人信息安全，会采取各种合理的物理、电子和管理方面的安全措施来保护您的个人信息，并尽最大合理努力使您的个人信息不会被泄露、毁损或者丢失，包括但不限于访问权限管理、限制访问、加密处理与相关人员签署保密协议、监控操作情况等措施。
      </p>
      <p>
        请您妥善保管您的注册账户、密码及其他身份信息。一旦您泄露该信息，如可能会对您有不利影响。您可立即与会诊帮或相关方联系，以使损失最小化。
      </p>
      <p class="bold">六、我们如何使用Cookie（网络跟踪器）和同类技术</p>
      <p>
        为使您获得更为轻松的访问体验，您访问会诊帮平台时，会诊帮会通过采用各种技术收集和存储相关信息，包括使用小型数据文件识别您的身份，这么做是为了解您的使用习惯，帮您省去重复输入注册信息的步骤，或者帮助判断您的帐户安全。这些数据可能是Cookie、Flash
        Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookies”）。
      </p>

      <p>
        请您理解，会诊帮的某些服务只能通过使用Cookie才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝会诊帮平台的Cookie，但如拒绝，在某些情况下可能会影响您安全访问会诊帮官网及/或会诊帮移动应用程序提供的产品或服务。
      </p>
      <p class="bold">七、未成年人隐私权保护</p>
      <p>
        会诊帮重视未成年人的信息保护。如您为14周岁以下的未成年人，请务必请您的监护人仔细阅读本隐私政策，在提供个人信息前，获得您的监护人的同意。
      </p>
      <p class="bold">八、您的个人权利</p>
      <p>
        您明确知晓并同意，在符合法定的情形下，您对个人信息拥有合法的查阅、更正、删除、撤回同意权。与此同时，您行使上述权利不得与会诊帮需要遵循的监管义务相违背，也不得超出下列合法需求范围。
      </p>
      <p>（一）查阅和更正您的个人信息</p>
      <p>
        您可以与会诊帮联系，核实会诊帮是否持有您的个人信息，并查阅您的个人信息。如会诊帮所持有的您的个人信息存在错误，您可以要求会诊帮更正您的个人信息。
      </p>
      <p>
        您可以通过微信小程序——我的——头像，对身份信息、手机号码、电子邮箱、售后地址等信息进行查看和更正。
      </p>
      <p>（二）删除您的个人信息</p>
      <p>1、在以下情形中，您可以向我们提出删除个人信息的请求：</p>
      <p>（1）如果我们违反法律法规规定，收集、使用个人信息的</p>
      <p>（2）会诊帮违反与您的约定，收集、使用个人信息的。</p>
      <p>
        2、会诊帮违反法律法规的规定或违反与您的约定，向第三方转让、共享您的个人信息的，如您要求删除，我们应立即停止共享、转让行为，并通知第三方及时删除。
      </p>
      <p>
        3、会诊帮违反法律法规的规定，或者违反与您的约定，公开披露您的个人信息，且您要求删除的，会诊帮应立即停止披露行为，且要求相关接收方删除相应信息。
      </p>
      <p>
        （三）改变您授权同意的范围或撤回您的授权
        您可以通过关闭功能设备、注销账户等方式撤回您的授权或改变授权同意的范围。请您理解，当你撤回同意或授权后，我们无法为您提供撤回同意或授权的服务。您撤回同意或授权的决定，不会影响此前基于您的个人授权而开展的个人信息处理。
      </p>

      <p>（四）响应您的上述请求</p>
      <p>在以下情形中，按照法律法规要求，会诊帮将无法响应您的请求：</p>
      <p>（1）与国家安全、国防安全有关的；</p>
      <p>（2）与公共安全、公共卫生、重大公共利益有关的；</p>
      <p>（3）与犯罪侦查、起诉、审判和执行判决等有关的；</p>
      <p>（4）有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
      <p>（5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
      <p>（6）涉及商业秘密的。</p>
      <p class="bold">九、隐私政策的更新</p>
      <p>
        会诊帮可能适时修订本隐私政策，一旦本隐私政策内容发生变更，会诊帮会公布最新的隐私政策，并通过显著的方式通知您。若您在本隐私政策修订后继续使用会诊帮提供的产品或服务，这表示您已充分阅读、理解并愿意受修订后的隐私政策约束。
      </p>
      <p class="bold">十、如何联系我们</p>
      <p>如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系：</p>
      <p>- 会诊帮平台在线客服</p>
      <p>- 电话：400-863-1599</p>
      <p>一般情况下，我们将在【7 】个工作日内回复。</p>

      <p>
        如果您对我们的回复不满意，特别是如果您认为我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报，或通过向被告住所地有管辖权的人民法院提起诉讼解决。
      </p>
    </div>
  </div>
</template>
	
	<script>
</script>
	
  <style scoped lang="scss">
.xy-box {
  .title {
    text-align: center;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .content {
    line-height: 20px;
    color: #3c4247;
    font-size: 13px;
    text-align: justify;
    p {
      margin-top: 10px;
    }
    .bold {
      font-weight: bold;
      color: #121314;
      font-size: 14px;
    }
  }
}
</style>