export function getDate(T, te) {
  if (!T) {
    return "";
  }

  let y = T.getFullYear();
  let m = T.getMonth() + 1 < 10 ? "0" + (T.getMonth() + 1) : T.getMonth() + 1;
  let d = T.getDate() < 10 ? "0" + T.getDate() : T.getDate();
  if (te == 1) {
    return `${y}-${m}-${d}`;
  }
  let q = T.getHours() < 10 ? "0" + T.getHours() : T.getHours();
  let w = T.getMinutes() < 10 ? "0" + T.getMinutes() : T.getMinutes();
  let e = T.getSeconds() < 10 ? "0" + T.getSeconds() : T.getSeconds();
  return `${y}-${m}-${d} ${q}:${w}:${e}`;
}
// 格式化的时间 获取时间戳
export function getDateNum(dateText) {
  if (!dateText) {
    return 0;
  }
  dateText = dateText.replace(/-/g, "/");
  let dateNum = new Date(dateText).getTime();
  return dateNum;
}
//http转换为https
export function changhttp(ta) {
  if (!ta) {
    return "";
  }
  if (ta.length > 5 && ta.indexOf("https") != 0 && ta.indexOf("http") == 0) {
    ta = ta.replace(/^http/, "https");
  }
  return ta;
}
export default {
  getDate,
  changhttp,
  getDateNum,
};
