/*为简洁化http文件而创建*/
// 请求后台域名
let MY_HOSTNAME = window.location.hostname;
if (MY_HOSTNAME != "wxhzb.xuekejianshe.com") {
  MY_HOSTNAME = "wxcheshi.xuekejianshe.com";
}
export { MY_HOSTNAME };
export default {
  MY_HOSTNAME,
  httpPorts: {
    //设置接口集合 超时时间 请求类型..... 默认、无的可以不写
    login: {
      url: "/patient/login",
      method: "POST",
      isDownload: false, //true为下载,
      name: "登录",
      isToken: 0, // 不需要 1需要(默认 un) 2：皆可
      fallNUL: false, //是否需要去掉值为空字符的参数
      // stringify: true,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    },

    getToken: {
      url: "/patient/getToken",
      method: "POST",
      isToken: 0,
      name: "微信授权",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    },
    wxSendSmsCode: {
      url: "/patient/sendSmsCode",
      method: "POST",
      isToken: 0,
      name: "获取手机验证码",
    },
    updatePatientPhone: {
      url: "/patient/updatePatientPhone",
      method: "POST",
      name: "修改手机号",
    },
    getChatRoomStatusRoom: {
      url: "/chat-room/getChatRoomStatusRoom",
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      name: "首页患者会诊提醒",
    },

    //聊天室
    getChatRoomMsgList: {
      url: "/chat-room-msg/getChatRoomMsgList",
      method: "POST",
      name: "获取聊天室消息记录",
    },
    sendRoomMsg: {
      url: "/chat-room-msg/sendRoomMsg",
      method: "POST",
      name: "聊天室发送消息",
    },
    inviteFamily: {
      url: "/patient-family/inviteFamily",
      urlAfter: ["roomId"],
      method: "POST",
      name: "邀请家属",
    },
    upMsgRead: {
      url: "/room-msg-read/upMsgRead",
      method: "POST",
      name: "聊天室信息标记为已读",
    },
    getPatientInfo: {
      url: "/patient/getPatientInfo",
      method: "POST",
      name: "获取用户信息",
    },
    getOrderJSAPIPrepay: {
      url: "/assistant-order/getOrderJSAPIPrepay",
      method: "POST",
      name: "获取预订单",
    },

    //
    // ----
    // getUserSig: {
    //   url: "/chat-room/userSigInAssistantConsultId",
    //   urlAfter: ["userId", "assistantConsultId"],
    //   method: "GET",
    //   name: "获取聊天会议相关信息", //获取userSig、房间号
    // },
    // userSigInRoomId--废弃
    userSigInVoiceRoomId: {
      url: "/chat-room/userSigInVoiceRoomId/",
      urlAfter: ["userId", "roomID"],
      method: "GET",
      name: "获取会诊语音相关信息", //获取userSig、房间号
    },
    userSigInVideoRoomId: {
      url: "/chat-room/userSigInVideoRoomId",
      urlAfter: ["userId", "roomID"],
      method: "GET",
      name: "获取会诊视频相关信息", //获取userSig、房间号
    },
    roomUploadImg: {
      url: "/chat-room/uploadImg",
      method: "POST",
      name: "会诊文件上传",
      isLoading: true,
      loadingText: "上传中...",
      headers: {
        "Content-Type": "multipart/form-data;charset=utf-8",
      },
    },
    getRoomInfo: {
      url: "/chat-room/getRoomInfo",
      method: "POST",
      name: "获取聊天室信息",
    },

    // ------
    // 就诊人
    queryPatList: {
      url: "/see-doctor/queryAll",
      method: "POST",
      name: "就诊人列表",
    },
    patAdd: {
      url: "/see-doctor/save",
      method: "POST",
      name: "添加就诊人",
    },
    patUpdate: {
      url: "/see-doctor/modify",
      method: "POST",
      name: "修改就诊人",
    },
    patDelete: {
      url: "/see-doctor/delete",
      method: "POST",
      name: "删除就诊人",
      isString: true,
    },
    queryPatId: {
      url: "/see-doctor/queryAll",
      urlAfter: ["id"],
      method: "POST",
      name: "根据id查询就诊人",
      fallNUL: true,
    },
    seeDoctorUserInfo: {
      url: "/patient-assistant-consult/getCurrConsultInfo",
      method: "POST",
      name: "获取就诊人信息", //包括诉求等信息 --咨询》患者信息
    },
    getRoomOrderInfo: {
      url: "/chat-room/getRoomOrderInfo",
      method: "POST",
      name: "获取会诊病历资料", //会诊-病历资料 --通过房间号
    },
    // 科普频道
    articleChannelList: {
      url: "/article-channel/queryAll",
      method: "POST",
      name: "科普频道",
    },
    // 科普文章
    articleList: {
      url: "/article/queryAll",
      urlAfter: ["pageNum", "pageSize"],
      method: "POST",
      name: "科普文章",
    },
    queryArticleId: {
      url: "/article/getArticle",
      method: "POST",
      name: "根据id查询文章详情",
    },
    collectionArticle: {
      url: "/patient/collection",
      method: "POST",
      name: "收藏文章",
    },
    cancelCollectionArticle: {
      url: "/patient/cancel",
      method: "POST",
      name: "取消收藏文章",
    },
    queryPatCollection: {
      url: "/patient/collection",
      urlAfter: ["id", "articleChannelId", "pageNum", "pageSize"],
      method: "POST",
      name: "患者-收藏文章",
      fallNUL: true,
    },

    // 立即咨询
    venice: {
      url: "/chat-room/inquiriesConsult",
      method: "POST",
      name: "立即咨询",
    },
    // sendMsg: { --废弃
    //   url: "/patient-consult-dialogue/addConsultDialogue",
    //   method: "POST",
    //   name: "发送消息",
    // },
    getRoomUserList: {
      url: "/chat-room-member/getSendMsgAndRoomMemberList",
      method: "POST",
      name: "获取聊天室成员",
    },
    getRoomNowUserList: {
      url: "/chat-room-member/getChatRoomMemberNoPageList",
      method: "POST",
      name: "获取当前聊天室人员",
    },
    getMsgList: {
      url: "/chat-room/getPatientOrAccountToChatRoomList",
      method: "POST",
      fallNUL: true,
      name: "获取消息列表",
    },
    getMsgNoReadNum: {
      url: "/room-msg-read/getPatientOrAccountToChatRoomNoRead",
      method: "POST",
      name: "获取消息未读数量",
    },

    // setPatientCurrSessionRead: { //废弃
    //   url: "/patient-consult-dialogue/setPatientCurrSessionRead",
    //   method: "POST",
    //   name: "当前会话标记未已读",
    // },
    setPatientMsgRead: {
      url: "/room-msg-read/upMsgRead",
      method: "POST",
      name: "全部消息标记为已读",
    },

    uploadImg: {
      url: "/account/uploadImg",
      method: "POST",
      name: "文件上传",
      isLoading: true,
      loadingText: "上传中...",
      headers: {
        "Content-Type": "multipart/form-data;charset=utf-8",
      },
    },
    //
    // 首页
    queryPageData: {
      url: "/page-banner/queryAll",
      urlAfter: ["organizationId"],
      method: "POST",
      name: "查询全部数据以供更改",
    },
    queryCityPage: {
      url: "/page-banner/getPage",
      method: "POST",
      name: "根据市区名称查找页面数据",
    },
    organizationLeftList: {
      url: "/page-banner/queryPage",
      method: "POST",
      name: "可选择区域-首页配置过的",
    },

    // 关注医生
    queryPatFollow: {
      url: "/patient/follow",
      urlAfter: ["id", "pageNum", "pageSize"],
      method: "POST",
      name: "患者-关注医生",
      fallNUL: true,
    },
    followDoctor: {
      url: "/patient/follow",
      method: "POST",
      name: "关注医生",
    },
    cancelFollowDoctor: {
      url: "/patient/close",
      method: "POST",
      name: "取消关注医生",
    },
    queryAllArea: {
      url: "/area/queryAll",
      method: "POST",
      name: "区域",
    },
    queryMapsArea: {
      url: "/addr-maps/queryPage",
      method: "POST",
      name: "地区",
    },
    queryMapsQuery: {
      url: "/addr-maps/query",
      method: "POST",
      name: "地区",
    },
    queryAllOrganization: {
      url: "/organization/queryAll",
      method: "POST",
      name: "机构",
    },
    queryAreaOrganization: {
      url: "/organization/query/addrMps",
      method: "POST",
      name: "区域下机构",
    },
    queryDepartment: {
      url: "/organization-department/queryDepartment",
      method: "POST",
      name: "科室",
    },
    queryDoctorList: {
      url: "/doctor/getDoctor",
      method: "POST",
      name: "医生列表",
    },
    queryDoctorId: {
      url: "/doctor/getById",
      urlAfter: ["id"],
      method: "POST",
      name: "医生详情",
      fallNUL: true,
    },
    queryOrganizationId: {
      url: "/organization/query",
      method: "POST",
      name: "查询出机构下的医生集合",
    },
    getDepartmentAllHos: {
      url: "/organization-department/query",
      method: "POST",
      name: "全部科室-机构",
    },
    getDepartmentAllArea: {
      url: "/department/query",
      method: "POST",
      name: "全部科室-区域",
    },
    departmentAllBasicList: {
      url: "/department/queryAll",
      method: "POST",
      name: "全部标准科室",
    },
    // 订单
    getOrderConsultList: {
      url: "/assistant-order/getPatientOrderConsultList",
      method: "POST",
      name: "订单列表",
    },
    getOrderOrderStatus: {
      url: "/assistant-order/getOrderOrderStatus",
      method: "POST",
      name: "获取订单状态",
    },
    getAssistantOrderDetailById: {
      url: "/assistant-order/getAssistantOrderDetailById",
      method: "POST",
      name: "订单详情",
    },
    delAssistantOrder: {
      url: "/assistant-order/delAssistantOrder",
      method: "POST",
      name: "订单删除",
    },
    cancelOrder: {
      url: "/assistant-order/cancelOrder",
      method: "POST",
      name: "订单取消",
    },
    getServerTimes: {
      url: "/account/getServerTime",
      method: "POST",
      isToken: 0,
      name: "获取系统时间",
    },
    // 注销账号
    removePatient: {
      url: "/patient/removePatient",
      method: "POST",
      name: "账号注销",
    },
    // 抬头新增
    riseAdd: {
      url: "/rise/saveRise",
      method: "POST",
      name: "新增抬头",
    },
    // 抬头修改
    riseModify: {
      url: "/rise/modify",
      method: "POST",
      name: "修改抬头",
    },
    // 抬头列表
    riseList: {
      url: "/rise/list",
      method: "POST",
      name: "抬头列表",
    },
    // 抬头详情
    getRiseInfo: {
      url: "/rise/getRiseInfo",
      method: "POST",
      name: "获取抬头详情",
    },
    // 抬头删除
    riseDelete: {
      url: "/rise/delete",
      method: "POST",
      name: "抬头删除",
    },
    assistantOrderOrderStatus: {
      url: "/assistant-order/orderStatus",
      method: "POST",
      name: "订单列表", //开票页订单列表
    },
    invoiceSaveInvoice: {
      url: "/invoice/saveInvoice",
      method: "POST",
      name: "申请开票", //开票页订单列表
    },
    // 开票历史
    invoiceHistory: {
      url: "/invoice/history",
      method: "POST",
      name: "开票历史", //开票页订单列表
    },
    // 添加浏览
    loginCount: {
      url: "/sysCount/loginCount",
      method: "POST",
      name: "uv浏览",
      noAlert: true, //报错不提示
    },
    homeCount: {
      url: "/sysCount/homeCount",
      method: "POST",
      name: "pv浏览",
      noAlert: true, //报错不提示
    },
    zxCount: {
      url: "/sysCount/zxCount",
      method: "POST",
      name: "在线咨询数",
      noAlert: true, //报错不提示
    },
    cardCount: {
      url: "/doctor/cardCount",
      method: "POST",
      name: "咨询量",
      urlAfter: ["id"],
      noAlert: true, //报错不提示
    },
    zxCountClick: {
      url: "/doctor/zxCount",
      method: "POST",
      name: "点诊量",
      urlAfter: ["id"],
      noAlert: true, //报错不提示
    },
    // zxCount: {
    //   url: "/sysCount/zxCount",
    //   method: "POST",
    //   name: "在线咨询点击次数",
    //   noAlert: true, //报错不提示
    // },
  },

  //接口根url返回 Url为线上地址 DEV_URL为开发环境地址
  returnUrl: {
    Url: "https://" + MY_HOSTNAME + "/wx_hzb_server/hzb",
    // =========>开发地址
    // DEV_URL: "http://192.168.0.15:9002/hzb/",
    // DEV_URL: "http://192.168.0.16:9002/hzb/",
    // DEV_URL: "http://192.168.28.245:9002/hzb", //杨智--
    // DEV_URL: "http://58rh7k.natappfree.cc/hzb", //测试地址
    // DEV_URL: "http://192.168.0.17:9002/hzb/",
    // DEV_URL: "http://124.221.237.27:9002/hzb/",
    // DEV_URL: "http://wxcheshi.xuekejianshe.com/hzb",ssss
    DEV_URL: "https://wxcheshi.xuekejianshe.com/wx_hzb_server/hzb",
    // DEV_URL: "/hzb/wx_mmaicc2.1_server/hzb",
  },
};
