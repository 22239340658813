<template>
  <div class="view">
    <div class="top-search">
      <van-search
        v-model="title"
        show-action
        placeholder="搜索您感兴趣的内容"
        @search="onClickButton"
      >
        <template #action>
          <div @click="onClickButton">搜索</div>
        </template>
      </van-search>
    </div>

    <van-tabs
      class="tabs"
      title-active-color="#278EFF"
      v-model:active="activeName"
      @change="changeTabs"
    >
      <van-tab
        :name="item.id"
        :title="item.channelName"
        v-for="item in typeList"
        :key="item.id"
      >
        <div class="list">
          <div v-if="list.length == 0" class="listNull">
            <img src="../../assets/img/listNull2.png" />
            <p>未搜索到科普文章</p>
          </div>
          <div v-else>
            <van-list
              v-model:loading="loading"
              :finished="finished"
              :immediate-check="false"
              finished-text="没有更多了"
              v-model:error="error"
              error-text="请求失败，点击重新加载"
              @load="onLoad"
            >
              <div
                v-for="item2 in list"
                :key="item2.id"
                class="list-item"
                @click="toDetail(item2)"
              >
                <div class="item-img">
                  <img :src="item2.articleImg" alt="" />
                </div>

                <div class="item-con">
                  <h3 class="title">{{ item2.articleTitle }}</h3>
                  <p class="text">{{ item2.articleDescription }}</p>
                </div>
              </div>
            </van-list>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import {
  Picker,
  Toast,
  Field,
  Popup,
  Cell,
  Calendar,
  Button,
  Icon,
  Tabs,
  Tab,
  Search,
  List,
} from "vant";
export default {
  name: "mediaList",
  components: {
    [Picker.name]: Picker,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [Calendar.name]: Calendar,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Search.name]: Search,
    [List.name]: List,
  },
  data() {
    return {
      typeList: [],
      title: "",
      activeName: -1,
      list: [],
      //触底加载
      loading: false,
      finished: false,
      error: false,
      pageNum: 1,
      pageSize: 7,
    };
  },
  mounted() {
    // 频道
    this.getArticleChannelList();
    this.onLoad();
  },
  methods: {
    onLoad() {
      if (this.$route.query.patId) {
        this.$http
          .request("queryPatCollection", {
            id: this.$route.query.patId,
            articleChannelId: this.activeName,
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            articleTitle: this.title,
          })
          .then((res) => {
            this.pageNum++;
            this.list = this.list.concat(res.list);
            this.loading = false;
            if (res.total <= this.list.length) {
              this.finished = true;
            }
          })
          .catch((err) => {});
      } else {
        this.$http
          .request("articleList", {
            articleId: this.activeName,
            articleTitle: this.title,
            pageNum: this.pageNum,
            pageSize: this.pageSize,
          })
          .then((res) => {
            this.pageNum++;
            this.list = this.list.concat(res.list);
            this.loading = false;
            if (res.total <= this.list.length) {
              this.finished = true;
            }
          })
          .catch((err) => {});
      }
    },
    onClickButton() {
      this.list = [];
      this.pageNum = 1;
      this.loading = false;
      this.finished = false;
      this.error = false;
      this.onLoad();
    },
    getArticleChannelList() {
      this.$http
        .request("articleChannelList", {})
        .then((res) => {
          res.unshift({
            id: -1,
            channelName: "全部",
          });
          this.typeList = res;
        })
        .catch((err) => {});
    },

    changeTabs(val) {
      this.list = [];
      this.pageNum = 1;
      this.loading = false;
      this.finished = false;
      this.error = false;
      this.onLoad(val);
      // }
    },
    toDetail(item) {
      this.$router.push("/mediaDetail?id=" + item.id);
    },
    queryPatCollection(val) {
      this.$http
        .request("queryPatCollection", {
          id: this.$route.query.patId,
          articleChannelId: val,
        })
        .then((res) => {
          this.list = res;
        })
        .catch((err) => {});
    },
  },
  beforeDestroy() {},
};
</script>

<style scoped lang="scss">
.view {
  height: 100%;
  .top-search {
    height: 55px;
  }
  .tabs::v-deep(.van-tabs__line) {
    background-color: #278eff;
  }
  .tabs::v-deep(.van-tabs__content) {
    height: calc(100% - 44px);
    // overflow: auto;
  }
  .tabs {
    height: calc(100% - 55px);
    background: #ffffff;
  }
  .list {
    // height: 200vh;
    padding: 10px 0;
    .listNull {
      width: 4.26rem;
      margin: calc(50vh - 280px) auto 0;
      img {
        height: 2.74rem;
      }
      p {
        text-align: center;
        opacity: 0.5;
      }
    }
    .list-item {
      width: 7.1rem;
      color: #272d3b;
      border-bottom: 1px solid #ccc;
      margin: 10px;
      padding: 10px;
      padding-bottom: 20px;
      box-sizing: border-box;
      display: flex;
      .item-img {
        width: 90px;
        height: 90px;
        border-radius: 8px;
        overflow: hidden;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .item-con {
        width: calc(100% - 90px);
        margin-left: 15px;
        .title {
          color: #333;
          font-size: 16px;
          line-height: 30px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1; //元素几行显示
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
        .text {
          font-size: 14px;
          color: #999;
          line-height: 22px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3; //元素几行显示
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
      }
    }
  }
}
</style>
