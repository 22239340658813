<template>
  <div class="xy-box">
    <h3 class="title">在线咨询服务协议</h3>
    <div class="content">
      <p>甲方：</p>
      <p>乙方：会诊帮医生集团（深圳）有限公司</p>
      <p class="bold">一、服务内容</p>
      <p>
        根据患者疾病实际需要，乙方受甲方委托，为患者提供邀请咨询服务专家等相关在线咨询服务事项。
      </p>
      <p class="bold">二、服务形式及付款信息</p>
      <p>
        1、双方一致同意，甲方向乙方提出需求，为甲方提供在线咨询服务。服务形式为图片咨询服务、语音咨询服务、视频咨询服务三种，由甲方确认选择服务形式。
      </p>
      <p>
        图片咨询服务：医患双方在会诊帮小程序平台，以文字形式咨询健康相关问题。
      </p>
      <p>
        语音咨询服务：医患双方在会诊帮小程序平台，以语音会话方式咨询健康相关问题。
      </p>
      <p>
        视频咨询服务：医患双方在会诊帮小程序平台，以视频会话方式咨询健康相关问题。
      </p>
      <p>
        2、双方一致同意，甲方按标准服务金额支付在线咨询相关的服务费用： 
        <table border="1" cellspacing="0" class="table1">
            <tr>
                <td>咨询形式</td>
                <td>服务形式</td>
                <td>价格（元）</td>
                <td>服务时间</td>
            </tr>
            <tr>
                <td rowspan="3">线上咨询</td>
                <td>图片</td>
                <td>400</td>
                <td rowspan="3">依据平台服务时间</td>
            </tr>
            <tr>
                <td>电话</td>
                <td>600</td>
            </tr>
            <tr>
                <td>视频</td>
                <td>1000</td>
            </tr>
        </table>
      </p>

      <p>3、付费时效及付款方式</p>
      <p>
        ①、乙方收到咨询申请后，会根据甲方申请类型推送对应付款卡片，甲方在平台接收查看支付卡片，并于本协议签订之日起
        1 日内通过线上向乙方足额支付咨询服务费。
      </p>

      <p>
        ②、甲方如为同一病例申请多个专家咨询，乙方会推送对应数量的付款卡片，甲方须逐一进行接收并完成支付。
      </p>
      <p>
        ③、甲方如为不同病例申请多个专家咨询，乙方会推送对应数量的付款卡片，甲方须逐一进行接收并完成支付。
      </p>
      <p>
        4、乙方收款账户信息
        <table border="1" cellspacing="0" class="table2">
            <tr>
                <td class="td1">户名</td>
                <td class="td2">会诊帮医生集团（深圳）有限公司</td>
            </tr>
            <tr>
                <td class="td1">账号</td>
                <td class="td2">7559 3769 6710 902</td>
            </tr>
            <tr>
                <td class="td1">开户银行</td>
                <td class="td2">招商银行股份有限公司深圳福民支行</td>
            </tr>
        </table>
      </p>
      <p>5、发票开具 </p>
      <p>服务完成后，甲方可向乙方申请开具服务票据。</p>
      <p>6、退费说明及方式</p>
      <p>①、缴费成功后，一般情况下不予退费。</p>
      <p>
        ②、如未发生相关服务，或因不可抗力因素无法提供相关服务的，甲方可以向乙方提出退费申请，经乙方核实后办理退费，所退费用原路返回。
      </p>
      <p>
        ③、协议签订后，乙方在已确认专家履约时间的情况下，甲方无故违约，需支付协议款的30%，作为违约费。
      </p>
      <p class="bold">三、甲方的权利和义务</p>
      <p>
        1、本协议系甲乙双方签订，当甲方系患者本人时，本合同自然约束甲乙（患者）双方：当甲方非患者本人时，乙方不审查甲方与患者的权利义务关系，因甲方与患者之间发生权利义务争议而影响本协议履行时，乙方可向甲方主张权利。
      </p>
      <p>
        2、甲方向乙方申请咨询服务时，应当提供详细准确的就诊患者病历资料，身份资料等信息，需要将患者的诊断、治疗等全面的信息无条件提供给乙方，以确保服务的准确及有效性。
      </p>
      <p>
        3、专家的健康管理建议和意见仅供甲方参考，专家的言论并不代表乙方立场。
      </p>
      <p>
        4、甲方须确保联络电话无误，若由于电话错误无法及时联系上甲方，甲方须承担相应后果。
      </p>
      <p>5、甲方有任何疑问可前往服务中心咨询或拨打客服电话：400-863-1599。</p>
      <p class="bold">四、乙方的权利和义务</p>
      <p>
        1、乙方应保证远程会诊的基础条件，包括场地环境、网络状态、数据传输接口、硬件设备等配置标准。
      </p>
      <p>
        2、乙方应当严格遵守相关法律、法规、信息标准和技术规范，建立健全远程咨询服务相关的管理制度，完善医疗质量与医疗安全保障措施，确保医疗质量安全，保护就诊患者隐私，未经授权不得将患者信息向无关第三方透露，维护甲方合法权益。
      </p>
      <p>
        3、乙方不解答任何涉及医疗事故鉴定或医疗纠纷类医疗相关的问题，专家健康管理意见不得作为医疗争议的依据。
      </p>
      <p class="bold">五、不可抗力因素</p>
      <p>
        1、在合同的执行过程中如果出现了天气等不可抗力因素，将影响合同的正常履行时，受不可抗力影响的一方应尽快将不可抗力事故的情况通知对方，将活动日期推迟，具体日期双方议定。若乙方单方面决定时间未经甲方同意，甲方可要求全额退款。
      </p>
      <p>
        2、因不可抗力造成的对履行合同的延误或无法正常履行时，受不可抗力影响的一方不负法律责任。
      </p>
      <p class="bold">六、特别申明</p>
      <p>
        本协议未尽事宜应通过友好协商确定。因本协议产生任何纠纷的，先协商解决、协商不成的，可提交至乙方所在地法院进行诉讼。
      </p>
      <p>
        本协议自双方确认签字盖章之日起生效。协议壹式贰份，甲乙双方各执壹份，相关部门备案壹份，且均具有同等法律效力。
      </p>
      <p>甲方代表签字（盖章）：</p>
      <p>乙方代表签字（盖章）：</p>
      <p>联系电话：</p>
      <p>联系电话：</p>
      <p>签订时间：___年___月___日</p>
      <p>签订时间：___年___月___日</p>
    </div>
  </div>
</template>
    
    <script>
</script>
    
  <style scoped lang="scss">
.xy-box {
  .title {
    text-align: center;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .content {
    line-height: 20px;
    color: #3c4247;
    font-size: 13px;
    text-align: justify;
    p {
      margin-top: 10px;
    }
    .bold {
      font-weight: bold;
      color: #121314;
      font-size: 14px;
    }
    .table1 {
      width: 100%;
      border-collapse: collapse;
      td {
        width: 25%;
        padding: 5px;
        text-align: center;
      }
    }
    .table2 {
      width: 100%;
      border-collapse: collapse;
      .td1 {
        width: 25%;
        padding: 5px;
      }
      .td2 {
        width: 75%;
        padding: 5px;
      }
    }
  }
}
</style>