<template>
  <div>
    <div class="top">
      <div class="allRead" @click="readAll">
        <img src="../../assets/img/icon/qingchu.png" />
        全部已读
      </div>
    </div>
	<van-tabs  @click-tab="onClickTab"  class="tabs" background="transparent" color="#278EFF"  title-active-color="#278EFF" v-model:active="msgListActive" >
	  <!-- <van-tab :title="'全部（'+msgUnreadNum+'）'">
		  <type-msg-list  v-if="msgListActive==0" style="height: calc(100vh - 134px);" :type="1" ></type-msg-list>
	  </van-tab> -->
	  <van-tab  :title="'咨询消息（'+(zxNoread>99?'99+':zxNoread)+'）'">
		  <type-msg-list :isSelect="msgListActive==0" ref="zxMsgList" v-show="msgListActive==0" v-if="activeList.indexOf(0)!=-1" style="height: calc(100vh - 134px);" :type="2" ></type-msg-list>
	  </van-tab>
	  <van-tab  :title="'会诊室消息（'+(hzNoread>99?'99+':hzNoread)+'）'">
		  <type-msg-list :isSelect="msgListActive==1" ref="hzMsgList" v-show="msgListActive==1"  v-if="activeList.indexOf(1)!=-1" style="height: calc(100vh - 134px);" :type="3" ></type-msg-list>
	  </van-tab>
	</van-tabs>
  </div>
</template>

<script>
import {Tab,Tabs } from "vant";
import typeMsgList from "./list.vue"
export default {
  name: "messageList",
  components: {
	[Tab.name]:Tab,
	[Tabs.name]:Tabs,
	typeMsgList
  },
  props:["isSelect","msgUnreadNum",'hzNoread','zxNoread'],
  data() {
    return {
	  msgListActive:0,
	  activeList:[0],
    };
  },
  watch:{
	"isSelect"(t){
		if(t){
			// this.getList();
		}
	}  
  },
  created() {
	  // this.$emit("getMsgUnreadNum")
  },
  methods: {
    // this.$emit("update:msgUnreadNum",this.unreadNum)
    // 全部标记为已读
    readAll() {
      this.$http
        .request("setPatientMsgRead", {
          patientId: this.$cache.local.getItem("patientId"),
        })
        .then(() => {
          console.log("操作成功");
		  if(this.$refs.zxMsgList){
			  this.$refs.zxMsgList.readAll()
		  }
		  if(this.$refs.hzMsgList){
			  this.$refs.hzMsgList.readAll()
		  }
		  this.$emit("getMsgUnreadNum")
        })
        .catch(() => {});
    },
	// 点击tab
	onClickTab(){
		this.$emit("getMsgUnreadNum")
		if(this.activeList.indexOf(this.msgListActive)==-1){
			this.activeList.push(this.msgListActive)
		}
	},
  },
  watch: {
    // 没有触发
    "$store.state.wsMsgList": {},
    // 监听wsmsgList更改
    "$store.state.wsMsgNum"() {},
    // 监听ws最新消息
    "$store.state.wsNewMsg"(item) {
      console.log(item, "监听最新消息");
    },
    // 监听新增咨询消息
    "$store.state.addNewZx"(item) {},
    // 监听新增会诊消息
    "$store.state.addHzRoom"(item) {},
  },
};
</script>

<style lang="scss" scoped>
.top {
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  .allRead {
	  float: right;
	  margin: 0 10px;
    height: 40px;
    font-weight: 400;
    color: #278eff;
    font-size: 12px;
    img {
      vertical-align: middle;
      height: 16px;
    }
  }
}
.tabs::v-deep(.van-tabs__line) {
    background-color: #278eff;
  }
.list-container {
  height: calc(100% - 68px);
  overflow: auto;
  .list {
    padding: 0 16px;
    .item {
      display: flex;
      height: 112px;
      border-bottom: 1px solid #eeeeee;
      width: 100%;
      .item-left {
        width: 48px;
        display: flex;
        align-items: center;
        .item-left-content {
          width: 48px;
          height: 48px;
        }
      }
      .item-right {
        padding-left: 15px;
        width: calc(100% - 63px);
        .item-right-top {
          line-height: 48px;
          display: flex;
          justify-content: space-between;
          width: 100%;
          .item-name {
            font-weight: 600;
            color: #333333;
            font-size: 16px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: calc(100% - 130px);
          }
          .item-date {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            width: 120px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .item-message {
          font-weight: 400;
          color: #999999;
          line-height: 24px;
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
        }
        .item-p {
          font-weight: 400;
          color: #278eff;
          line-height: 20px;
          font-size: 14px;
        }
      }
      .item-img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }
    }
  }
}
.listNull-container {
  text-align: center;
  .listNull {
    width: 135px;
    margin-top: 20vh;
  }
  .text {
    font-size: 14px;
    font-weight: 400;
    color: #278eff;
    margin-top: 4px;
    line-height: 20px;
  }
}
</style>