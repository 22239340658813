<template>
	<router-view />
</template>

<script>
export default {
	name:"app",
	data() {
		return{
			document:"",
		}
	},
	created() {
		console.log("++++++++++")
		this.$store.commit("changeVisibilityState",document[this.getVisibilityState()])
		this.changeMyWindow("start");
	},
	mounted() {
		this.$router.isReady()
		.then(()=>{
			if(this.$route.path!="/login"){
				console.log("执行到这了",this.$route)
				this.$myws.initMyWs(this.$cache.local.getItem("patientId"));
			}
		})
		.catch((e)=>{
			console.log(e)
		})
	},
	methods:{
		// 监听窗口更改
				changeMyWindow(t){
					let _this=this;
					let document=window.document;
					function getHiddenProp(){
					    var prefixes = ['webkit','moz','ms','o'];
					    // if 'hidden' is natively supported just return it
					    if ('hidden' in document) return 'hidden';

					    // otherwise loop over all the known prefixes until we find one
					    for (var i = 0; i < prefixes.length; i++)
					    {
					        if ((prefixes[i] + 'Hidden') in document)
					            return prefixes[i] + 'Hidden';
					    }
					    // otherwise it's not supported
					    return null;
					}
					var visProp = getHiddenProp();
					// console.log(visProp)
					 var evtname = visProp.replace(/[H|h]idden/, '') + 'visibilitychange';
					if (visProp)
					{

						if(t=='start'){
							// console.log('监听窗口更改函数',evtname)
							_this.evtname=evtname;
							if(!document[evtname]){
								document.addEventListener(evtname,_this.evtFun);
							}
						}else if(t=='end'){
							console.log('注销')
								document.removeEventListener(evtname,_this.evtFun)
							}
					}
				},
				 evtFun(){
					console.log(document[this.getVisibilityState()])
					this.$store.commit("changeVisibilityState",document[this.getVisibilityState()])
						if(document[this.getVisibilityState()]=="visible"){
							this.$getFwDate();
						}
					},
				getVisibilityState(){
				    var prefixes = ['webkit', 'moz', 'ms', 'o'];
				    if ('visibilityState' in document) return 'visibilityState';
				    for (var i = 0; i < prefixes.length; i++)
				    {
				        if ((prefixes[i] + 'VisibilityState') in document)
				            return prefixes[i] + 'VisibilityState';
				    }
				    return null;
				},
	},
	//
	watch:{
		// 监听路由 --如果
		// "$route":{
		// 	 handler:function (r) {},
		// 	// immediate:true
		// }
	}
}
</script>
<style lang="scss">
* {
	padding: 0;
	margin: 0;
}
</style>
